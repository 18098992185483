<script setup>
    import { defineProps } from 'vue'

    defineProps({
        data: Object,
    })

    const api = process.env.VUE_APP_API_URL
</script>

<template>
    <div class="object-video">
        <v-row>
            <v-col
                cols="12"
                lg="4"
                order="1"
                :order-lg="!data?.invertLayout ? 1 : 2"
            >
                <div
                    v-if="data?.wysiwyg"
                    class="text"
                    v-html="data?.wysiwyg"
                />
            </v-col>
            <v-col
                cols="12"
                lg="8"
                order="2"
                :order-lg="!data?.invertLayout ? 2 : 1"
            >
                <div
                    v-if="data?.video"
                    class="video"
                    :style="{ aspectRatio: data?.aspectRatio }"
                >
                    <video
                        v-if="data?.video?.type === 'asset'"
                        controls
                        :poster="api + data?.video?.poster?.fullpath"
                    >
                        <source
                            :src="api + data?.video?.data?.fullpath"
                            type="video/mp4"
                        />
                    </video>
                    <iframe
                        v-if="data?.video?.type === 'youtube'"
                        :src="'https://www.youtube.com/embed/' + data?.video?.data?.id"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    />
                    <iframe
                        v-if="data?.video?.type === 'vimeo'"
                        :src="'https://player.vimeo.com/video/' + data?.video?.data?.id"
                        frameborder="0"
                        allowfullscreen
                    />
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<style scoped lang="scss">
    .object-video {
        .video {
            width: 100%;

            video,
            iframe {
                width: 100%;
                height: 100%;
            }
        }
    }
</style>
