<script setup>
import {defineProps} from "vue";
import teaserAccordion from "@/components/custom/dynamicComponents/teaserAccordion.vue";
import clubmasterAccordion from "@/components/custom/dynamicComponents/clubmasterAccordion.vue";
import timelineCards from "@/components/custom/dynamicComponents/timelineCards.vue";
import membersExpansionPanel from "@/components/custom/dynamicComponents/membersExpansionPanel.vue";
import memberBoxes from "@/components/custom/dynamicComponents/memberBoxes.vue";
import memberRows from "@/components/custom/dynamicComponents/memberRows.vue";
import titledList from "@/components/custom/dynamicComponents/titledList.vue";
import titledListTwoCols from "@/components/custom/dynamicComponents/titledListTwoCols.vue";

defineProps({
    objectRelation: Object,
});

const components = {
    teaserAccordion,
    clubmasterAccordion,
    timelineCards,
    membersExpansionPanel,
    memberBoxes,
    memberRows,
    titledList,
    titledListTwoCols,
}
</script>

<template>
    <component :is="components[objectRelation.templateRelation]" :data="objectRelation.dataRelation"/>
</template>
