<template>
    <div class="contentSectionSteps">
        <customContentSectionHeader :tag="contentSectionStep.tag" :headline="contentSectionStep.headline" position="left"/>
        <v-row>
            <v-col
                cols="12"
                class="v-col-md-6"
            >
                <p class="pb-4">{{contentSectionStep.description}}</p>
                <v-img :src="api + contentSectionStep?.leftPicture?.fullpath"></v-img>
            </v-col>
            <v-col
                cols="12"
                class="v-col-md-6"
            >
                <v-img :src="api + contentSectionStep?.rightPicture?.fullpath" class="mb-8"></v-img>
                <v-card
                    :class="(i % 2 === 1 ? 'grid-area-switch' : '')"
                    v-for="(step,i) in contentSectionStep.steps" :key="i"
                    variant="text"
                    rounded="lg"
                >
                    <template #title >
                        <v-img width="4rem" :src="api + step.icon?.fullpath"></v-img>
                        <div class="step-title" v-html="step.text"></div>
                    </template>
                    <template #append>
                        <p class="bigNumber">{{ i+1 < 10 ? '0' + (i + 1).toString() : i+1}}</p>
                    </template>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script setup>
import {defineProps} from 'vue'
import customContentSectionHeader from '@/components/reuseables/customContentSectionHeader.vue'

defineProps({
        contentSectionStep: Object
    })

    const api = process.env.VUE_APP_API_URL;

</script>

<style scoped>
    .v-card{
        margin: 0.75rem 0 0.9rem 0;
        box-shadow: 0 0.25rem 0.625rem 0 rgba(0, 0, 0, 0.1);
    }

    .bigNumber {
        font-size: 4rem;
        font-weight: 800;
        line-height: 2rem;
        text-transform: capitalize;
    }

    :deep(.grid-area-switch){
        .v-card-item{
            grid-template-areas: "append content title";
            grid-template-columns: 1fr auto 0;
            .v-card-item__append{
                margin-right: auto;
                margin-left: 0;
                white-space: nowrap;
            }

            @media (min-width: 1280px) {
                grid-template-columns: 1fr 3fr 0;
            }


        }
    }

    :deep(.v-card-item){
        padding: 1rem 1rem;
        grid-template-columns: 0 auto 1fr;
        gap: 1rem;
        .v-card-item__append{
            margin-left: auto;
            white-space: nowrap;
        }
        @media (min-width: 1280px) {
            grid-template-columns: 0 3fr 1fr;
        }

    }

    :deep(.v-card-title){
        display: flex;
        align-items: center;
        gap: 1rem;
        .v-img{
            max-width: 4rem;
        }
        .step-title{
            p{
                text-wrap: wrap;
            }
        }
    }
</style>
