<template>
    <div class="image-gallery-container" v-if="data.content && data.content[0]">
        <v-carousel
            hide-delimiters
            :show-arrows="columns < data.content.length"
        >
            <template v-for="(item, i) in data.content.length">
                <v-carousel-item
                    v-if="(i + 1) % columns === 1 || columns === 1"
                    :key="i"
                >
                    <v-row class="flex-nowrap h-100" :item="item" no-gutters>
                        <template v-for="(n, j) in columns">
                            <v-col
                                v-if="+i + j < data.content.length"
                                :key="j"
                                style="display: flex; justify-content: center"
                                @click="
                                    (visibleRef = !visibleRef),
                                        (indexRef = i + j)
                                "
                                v-ripple
                            >
                                <custom-image
                                    v-if="data.content[+i + j].element.fullpath"
                                    :image="data.content[+i + j].element"
                                    height="100%"
                                    width="100%"
                                    cover
                                    :console-log="true"
                                />
                            </v-col>
                        </template>
                    </v-row>
                </v-carousel-item>
            </template>
        </v-carousel>
        <vue-easy-lightbox
            :visible="visibleRef"
            :imgs="imgsRef"
            :index="indexRef"
            @hide="onHide"
            rotate-disabled
            zoom-disabled
            pinch-disabled
            move-disabled
        />
    </div>
</template>

<script setup>
import {defineProps, computed, ref} from "vue";
import {vuetify} from "../../plugins/vuetify";
import CustomImage from "@/components/reuseables/customImage.vue";
import VueEasyLightbox, {useEasyLightbox} from "vue-easy-lightbox";
import {getImagesForLightBox} from "@/mixins/global/globalMethods";

const props = defineProps({
        data: {
            type: Object,
            required: true,
        },
    }),
    display = ref(vuetify.display),
    columns = computed(() => {
        if (display.value.xxl) {
            return props?.data?.imageStripeXxl || 6;
        } else if (display.value.xl) {
            return props?.data?.imageStripeXl || 5;
        } else if (display.value.lg) {
            return props?.data?.imageStripeLg || 4;
        } else if (display.value.md) {
            return props?.data?.imageStripeMd || 3;
        } else if (display.value.sm) {
            return props?.data?.imageStripeSm || 2;
        } else {
            return 1;
        }
    });

const {
    // methods
    onHide,
    // refs
    visibleRef,
    indexRef,
    imgsRef,
} = useEasyLightbox({
    imgs: getImagesForLightBox(props.data?.content),
    // initial index
    initIndex: 0,
});
</script>

<style lang="scss" scoped>
.image-gallery-container {
    padding: 4rem;
    height: 420px;
    background-color: var(--light-color);

    .v-window {
        height: 100% !important;

        .v-row {
            gap: 1.5rem;

            .v-col {
                color: var(--medium-color);
                cursor: pointer;
            }
        }
    }
}
</style>
