<template>
    <!--    SETUP THE COLUMNS -->
    <v-col
        v-show="activeColumns[0]"
        v-bind="firstColumn"
        :id="'first-col-' + data.id"
    ></v-col>
    <v-col
        v-show="activeColumns[1]"
        v-bind="secondColumn"
        :id="'second-col-' + data.id"
    ></v-col>
    <v-col
        v-show="activeColumns[2]"
        v-bind="firstColumn"
        :id="'third-col-' + data.id"
    ></v-col>
    <v-col
        v-show="activeColumns[3]"
        v-bind="secondColumn"
        :id="'forth-col-' + data.id"
    ></v-col>

    <v-col
        v-if="data.links?.length > 0"
        cols="12"
    >
        <template
            v-for="(link, key) in data?.links"
            :key="key"
        >
            <custom-button-v2 :general-link="link" />
        </template>
        <template
            v-for="(file, key) in data?.files"
            :key="key"
        >
            <custom-button-v2 :general-file="file" />
        </template>
    </v-col>
    <template v-for="(img, k) in data.images">
        <v-col
            cols="12"
            md="6"
            xl="4"
            :key="'image-' + k"
            v-if="k > 0"
        >
            <custom-image
                :image="img"
                :hover="true"
                @click="(visibleRef = !visibleRef), (indexRef = k)"
                container-width="auto"
                container-height="auto"
            />
        </v-col>
    </template>
    <vue-easy-lightbox
        :visible="visibleRef"
        :imgs="imgsRef"
        :index="indexRef"
        @hide="onHide"
        rotate-disabled
        zoom-disabled
        pinch-disabled
        move-disabled
    />

    <!--    SETUP CONTENT, TELEPORTED TO THE RIGHT COL-->
    <template v-if="mounted">
        <!--        HEADLINE -->
        <Teleport
            v-if="data.headline"
            :to="findColumn('headline') + data.id"
        >
            <headline-container
                v-if="data.stylingHeadline === 'template-1' && data.headline"
                mb="xs"
            >
                {{ data.headline }}
            </headline-container>
            <h2
                v-else-if="data.headline"
                class="secondary-font-bold"
            >
                {{ data.headline }}
            </h2>
        </Teleport>

        <!--        IMAGE-->
        <Teleport :to="findColumn('image') + data.id">
            <custom-image
                v-if="data.images"
                :image="data.images[0]"
                :hover="true"
                @click="(visibleRef = !visibleRef), (indexRef = k)"
                container-height="auto"
                :background-image="{
                    data: data?.imagesBackground,
                    inverted: data?.imageBackgroundInverted,
                }"
            />
        </Teleport>

        <!--        TEXT -->
        <Teleport :to="findColumn('text') + data.id">
            <p
                v-if="data.intro"
                v-html="data.intro"
                class="secondary-font-bold mb-8"
            />
            <p
                v-if="data.text"
                v-html="data.text"
                class="secondary-font mb-6"
            />
            <custom-files :files="data.files" />
        </Teleport>
    </template>
</template>

<script setup>
    import { computed, defineProps, ref, onMounted } from 'vue'
    import CustomImage from '@/components/reuseables/customImage.vue'
    import VueEasyLightbox, { useEasyLightbox } from 'vue-easy-lightbox'
    import { getImagesForLightBox } from '@/mixins/global/globalMethods'
    import CustomFiles from '@/components/reuseables/customFiles.vue'
    import HeadlineContainer from '@/components/reuseables/headlineContainer.vue'
    import CustomButtonV2 from "@/components/reuseables/customButtonV2.vue"

    const mounted = ref(false)

    onMounted(() => {
        mounted.value = true
    })

    const props = defineProps({
            data: Object,
        }),
        {
            // methods
            onHide,
            // refs
            visibleRef,
            indexRef,
            imgsRef,
        } = useEasyLightbox({
            imgs: getImagesForLightBox(props?.data?.images),
            // initial index
            initIndex: 0,
        })

    const firstColumn = computed(() => {
            return {
                cols: props.data.widthBase,
                offset: props.data.widthBaseOffset,
                sm: props.data.widthSM,
                offsetSm: props.data.widthSMOffset,
                md: props.data.widthMD,
                offsetMd: props.data.widthMDOffset,
                lg: props.data.widthLG,
                offsetLg: props.data.widthLGOffset,
                xl: props.data.widthXL,
                offsetXl: props.data.widthXLOffset,
            }
        }),
        secondColumn = computed(() => {
            return {
                cols: props.data.widthBaseSecond,
                offset: props.data.widthBaseOffsetAfter,
                sm: props.data.widthSMSecond,
                offsetSm: props.data.widthSMOffsetAfter,
                md: props.data.widthMDSecond,
                offsetMd: props.data.widthMDOffsetAfter,
                lg: props.data.widthLGSecond,
                offsetLg: props.data.widthLGOffsetAfter,
                xl: props.data.widthXLSecond,
                offsetXl: props.data.widthXLOffsetAfter,
            }
        }),
        activeColumns = ref([false, false, false, false])

    function findColumn(content) {
        const arrangement = props.data.arrangement

        if (arrangement === 'text-image') {
            if (content === 'image') {
                activeColumns.value[3] = true
                return '#forth-col-'
            }

            if (content === 'text') {
                activeColumns.value[2] = true
                return '#third-col-'
            }

            if (content === 'headline') {
                activeColumns.value[0] = true
                return '#first-col-'
            }
        }

        else {
            if (content === 'image') {
                activeColumns.value[2] = true
                return '#third-col-'
            }

            if (content === 'text') {
                activeColumns.value[3] = true
                return '#forth-col-'
            }

            if (content === 'headline') {
                activeColumns.value[1] = true
                return '#second-col-'
            }
        }

        return null
    }
</script>
