<template>
    <div>
        <component :is="component[data.template]" :contentSectionStep="data"></component>
    </div>

</template>

<script setup>
    import { defineProps } from 'vue'
    import defaultTemplate from './templates/defaultTemplate.vue'
    import testTemplate from './templates/testTemplate.vue'


    defineProps({
        data: Object
    })

    const component = {
        defaultTemplate: defaultTemplate,
        testTemplate: testTemplate
    }
</script>
