import gql from "graphql-tag";
import generalImages from "../fragments/general/generalImages.graphql";
import trainerDetail from "../fragments/trainerDetail.graphql";
export const GET_TRAINER = gql`
    ${trainerDetail}
    ${generalImages}
    query ($filter: String) {
        getTrainerListing(
            filter: $filter
            defaultLanguage: "de"
            first: 1
        ) {
            edges{
                node{
                    ...trainerDetail
                }
            }
        }
    }
`;
