import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { vuetify } from './plugins/vuetify'
import { apolloProvider } from './plugins/apollo'
import VueNumber from 'vue-number-animation'
import VueMobileDetection from 'vue-mobile-detection'
import VueEasyLightbox from 'vue-easy-lightbox'
import { createPinia } from 'pinia'
import { i18n } from '@/plugins/i18n'
import { createGtmPlugin } from './plugins/gtm'

const pinia = createPinia()
const app = createApp(App)
const gtmPlugin = createGtmPlugin(app)

app.provide('apollo', apolloProvider.defaultClient)

import { createHead } from 'unhead'

// eslint-disable-next-line
const head = createHead()

import object_header from '@/components/object_header/object_header.vue'
import object_teaser from '@/components/object_Teaser.vue'

app.component('object_teaser', object_teaser)
app.component('object_header', object_header)
app.component('vue-number', VueNumber)

// GLOBAL METHODS
// import { getArrayOfFullpaths } from "@/mixins/global/globalComponents";
// app.config.globalProperties.$getArrayOfFullpaths = getArrayOfFullpaths;

import DOMPurify from 'dompurify'

function sanitizeHtml(html) {
    return DOMPurify.sanitize(html)
}

app.config.globalProperties.$sanitizeHtml = sanitizeHtml

app
    // .use(pinia)
    .use(apolloProvider)
    .use(i18n)
    .use(router)
    .use(vuetify)
    .use(pinia)
    .use(VueEasyLightbox)
    .use(VueNumber)
    .use(VueMobileDetection)
    .mount('#app')

gtmPlugin.initializeGtm()
