import { createI18n } from 'vue-i18n'
import axios from 'axios'

const apiUrl = process.env.VUE_APP_API_URL

const messages = {
    de: {},
}

export const i18n = createI18n({
    locale: 'de',
    fallbackLocale: 'de',
    messages: messages,
})

axios
    .get(apiUrl + '/locales/de.json', {
        withCredentials: false,
    })
    .then((response) => {
        messages.de = response.data
        i18n.global.setLocaleMessage('de', response.data)
    })
    .catch((error) => {
        console.error('Failed to load locale:', error)
    })
