<script setup>
    import { defineProps, onMounted, ref } from 'vue'
    import { ApolloClient, HttpLink, InMemoryCache, ApolloLink } from '@apollo/client/core'
    import { createApolloProvider } from '@vue/apollo-option'
    import object_importantMessage from '@/components/object_importantMessage.vue'
    import gql from 'graphql-tag'

    const fetchedData = ref(null)

    const props = defineProps({
        data: Object,
    })

    const httpLinkApi = new HttpLink({
        uri: props?.data?.endpoint,
    })

    const importedComponents = {
        object_importantMessage: object_importantMessage,
    }

    const middlewareLink = new ApolloLink((operation, forward) => {
        operation.setContext({
            headers: {
                Bearer: null,
                'Access-Control-Allow-Origin': '*',
                'X-API-Key': props?.data?.apiKey,
            },
        })
        return forward(operation)
    })

    const openLink = middlewareLink.concat(httpLinkApi)

    const apiClient = new ApolloClient({
        link: openLink,
        cache: new InMemoryCache(),
        defaultOptions: {
            fetchPolicy: 'no-cache',
        },
        connectToDevTools: true,
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    })

    const customApolloProvider = new createApolloProvider({
        defaultClient: apiClient,
        clients: {
            apiClient,
        },
    })

    onMounted(() => {
        customApolloProvider.clients.apiClient
            .query({
                query: gql`
                    ${props.data.query}
                `,
            })
            .then((response) => {
                fetchedData.value = response?.data
            })
    })
</script>

<template>
    <v-container>
        <component
            v-if="fetchedData?.[Object.keys(fetchedData)[0]]?.published"
            :is="importedComponents[props?.data?.component]"
            :data="fetchedData[Object.keys(fetchedData)]"
        />
    </v-container>
</template>
