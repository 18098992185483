<template>
    <custom-button v-if="themeStore?.[props?.target]?.overviewPage?.url" :to="getLink()">
        <img
            class="custom-icon small ml-0 mr-2"
            :src="api + '/frontend-icons/arrow-left.svg'"
            alt="zurück"
        />
        Zurück zur Übersicht
    </custom-button>
</template>

<script setup>
import { defineProps } from 'vue';
import CustomButton from "@/components/reuseables/customButton.vue";
const api = process.env.VUE_APP_API_URL;
import {useThemeStore} from "@/store/theme";
const themeStore = useThemeStore();

const props = defineProps({
    target: {
        type: String,
    },
})

function getLink() {
    return '/' + themeStore?.[props?.target]?.overviewPage?.url
}
</script>

<style lang="scss" scoped>
.custom-button {
    background-color: transparent;
}
</style>
