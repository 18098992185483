import { createGtm } from '@gtm-support/vue-gtm'

const gtmConfig = {
    id: process.env.VUE_APP_GTM_ID,
    defer: true,
    compatibility: false,
    enabled: false,
    debug: true,
    loadScript: true,
    // vueRouter: router,
}

const enableGtm = async (app) => {
    try {
        const gtmInstance = createGtm({
            ...gtmConfig,
            enabled: true,
        })
        app.use(gtmInstance)
    } catch (error) {
        console.error('Error enabling GTM:', error)
    }
}

const createGtmPlugin = (app) => {
    return {
        async initializeGtm() {
            console.log('initializeGtm')
            try {
                await enableGtm(app)
            } catch (error) {
                console.error('Error initializing GTM:', error)
            }
        },
    }
}

export { createGtmPlugin }
