<template>
    <div class="gallery-container spacing-bottom-sm" v-if="imageGallery.length > 0">
        <headline-container v-if="data.date">Bildergalerie</headline-container>

        <v-carousel v-if="imageGallery.length > 1" v-model="value" height="auto">
            <template v-slot:prev="{ props }">
                <custom-button height="100%" @click="props.onClick()" bg-color="transparent">
                    <img class="custom-icon" :src="api + '/frontend-icons/caret-left-fill__white.svg'" alt="zurück"/>
                </custom-button>
            </template>
            <v-carousel-item v-for="(image, i) in data.imageGallery" :key="i">
                <custom-image :image="image" @click="(visibleRef = !visibleRef), (indexRef = i)" width="100%" cover
                              v-ripple/>
            </v-carousel-item>

            <template v-slot:next="{ props }">
                <custom-button height="100%" @click="props.onClick()" bg-color="transparent">
                    <img class="custom-icon" :src="api + '/frontend-icons/caret-right-fill__white.svg'" alt="weiter"/>
                </custom-button>
            </template>
        </v-carousel>
        <custom-image v-else-if="imageGallery.length === 1" :image="data.imageGallery[0]" width="100%"
                      @click="visibleRef = !visibleRef" :console-log="true"/>
        <p class="img-title" v-if="props.showTextUnder" v-html="data?.imageGallery?.[value]?.title"/>

        <vue-easy-lightbox v-if="imageGallery.length > 0" :visible="visibleRef" :imgs="imageGalleryOriginal"
                           :index="indexRef" @hide="onHide" rotate-disabled zoom-disabled pinch-disabled move-disabled/>
    </div>
</template>

<script setup>
import CustomButton from "@/components/reuseables/customButton.vue";
import VueEasyLightbox from "vue-easy-lightbox";
import CustomImage from "@/components/reuseables/customImage.vue";
import HeadlineContainer from "@/components/reuseables/headlineContainer.vue";
import {defineProps, onMounted, ref} from "vue";
import {getImagesForLightBox} from "@/mixins/global/globalMethods";

const props = defineProps({
        data: Object,
        showTextUnder: {
            type: Boolean,
            default: false
        }
    }),
    imageGallery = ref([]),
    imageGalleryOriginal = ref([]),
    api = process.env.VUE_APP_API_URL,
    visibleRef = ref(),
    indexRef = ref(),
    onHide = ref(() => (visibleRef.value = false)),
    value = ref();

function getImageArray() {
    let array = [];
    if (props.data?.imageGallery) {
        props.data.imageGallery.forEach((image) => {
            array.push(image.image);
        });
    }
    imageGallery.value = array;
}

onMounted(() => {
    imageGalleryOriginal.value = getImagesForLightBox(props?.data.imageGallery);
    getImageArray();
});
</script>

<style lang="scss" scoped>
.gallery-container {
    //position: sticky;
    //top: 0;
    //z-index: 99999;
    .v-img {
        margin-bottom: 4rem;
    }
}

.v-carousel {
    width: 100%;
    aspect-ratio: 1/1;
    @media (max-width: 959px) {
        aspect-ratio: 1.618/1;
    }

    :deep(.v-window__container) {
        height: 100%;
    }

    :deep(.v-img) {
        height: 100%;
    }

    :deep(.v-window__controls) {
        padding: 0;

        button {
            padding: 0;
        }
    }

    :deep(.v-carousel__controls) {
        background-color: transparent;

        button {
            i {
                color: white;
                text-shadow: black 0 0 2px;
            }
        }
    }

    :deep(.v-carousel__controls) {
        .v-btn {
            &.v-btn--active {
                .v-btn__content {
                    background-color: white;
                }
            }

            .v-btn__overlay,
            .v-btn__underlay {
                display: none;
            }

            .v-btn__content {
                background-color: rgba(255, 255, 255, 0.5);
                transition: background-color 0.33s ease;
                height: 4px;
                width: 1.5rem;
                display: block;
                box-shadow: 1px 1px 1px 0 black;

                i {
                    display: none;
                }
            }
        }
    }
}
</style>
