<template>
    <div class="desktop-navigation">
        <template v-for="(page, p) in header.pages" :key="p">
            <custom-button
                class="primary-button"
                :to="page.element.children.length === 0 ? '/' + page.element.url : ''"
            >
                {{ page.element.urlText }}
                <img
                    v-if="page.element.children.length > 0"
                    class="custom-icon small"
                    :src="api + '/frontend-icons/chevron-down.svg'"
                    alt="öffnen"
                />
                <v-menu
                    v-if="page.element.children.length > 0"
                    activator="parent"
                    open-on-focus
                    open-on-hover
                    location="bottom"

                >
                    <v-list>
                        <template v-for="(child, index) in page.element.children" :key="index">
                            <v-list-item v-bind="getNavigationListItemProps(child)" class="secondary-button">
                                <v-list-item-title>
                                    {{ child.urlText }}
                                </v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-menu>
            </custom-button>
        </template>

        <navigation-header-desktop-additional-buttons
            v-if="header.additionalButtons"
            :additional-buttons="header.additionalButtons"
        />
    </div>
</template>

<script setup>
import CustomButton from "@/components/reuseables/customButton.vue";
import {defineProps} from "vue";
import NavigationHeaderDesktopAdditionalButtons
    from "@/components/navigation/navigationHeader/navigationHeaderDesktopAdditionalButtons.vue";
import {getNavigationListItemProps} from "@/mixins/global/globalMethods";

const api = process.env.VUE_APP_API_URL;

defineProps({
    header: {
        type: Object,
        required: true,
    },
});
</script>

<style lang="scss" scoped>
.secondary-button {
    min-height: 48px;
    text-transform: lowercase;

    .v-list-item-title {
        text-transform: inherit;
    }
}
</style>
