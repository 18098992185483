<template>
    <div class="news-latest-listing-vertical">
        <headline-container>Auch interessant</headline-container>
        <router-link
            class="single-news"
            v-for="(news, n) in newsArray" :key="n"
            :to="'/news/' + news.node.slug"
            v-ripple
            height="100%"
            width="100%"
        >
            <custom-image
                :image="getNewsImage(news)"
                cover
                containerHeight="6rem"
                containerWidth="6rem"
            />

            <div class="text-container">
                <span class="date" v-if="news.node.date">
                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-clock" viewBox="0 0 16 16">
                         <path
                             d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
                         <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
                    </svg>
                    {{ news.node.date }}
                </span>
                <h3 v-if="news.node.texts">
                    {{ news.node.texts[0].headline }}
                </h3>
            </div>
        </router-link>
    </div>
</template>

<script setup>
import {ref, onMounted} from "vue";
import CustomImage from "@/components/reuseables/customImage.vue";
import HeadlineContainer from "@/components/reuseables/headlineContainer.vue";
import {apolloProvider} from "@/plugins/apollo";
import newsListing from "@/graphql/querie/listing/newsListing.graphql";
import {getNewsImage} from "@/mixins/global/globalMethods";

const newsArray = ref({});

function loadNewsListing() {
    apolloProvider.defaultClient
        .query({
            fetchPolicy: "no-cache",
            query: newsListing,
            variables: {
                first: 3,
            },
        })
        .then((response) => {
            newsArray.value = response.data.getNewsListing.edges;
        });
}

onMounted(() => {
    loadNewsListing();
});
</script>

<style lang="scss" scoped>
.news-latest-listing-vertical {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    :deep(.headline-container) h2 {
        margin-bottom: 0;
    }

    .single-news {
        display: flex;
        text-decoration: none;
        gap: 1rem;

        .custom-image {
            flex-shrink: 0;
        }

        .text-container {
            .date {
                svg {
                    transform: translateY(2px);
                    margin-right: .5rem;
                }

            }

            h3 {
                font-size: 1rem;
                font-weight: bold;
                font-family: var(--secondary-font);
                margin-top: .5rem;
                line-height: 26px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
</style>
