<template>
    <headline-container class="newsLatestListing-headline news-latest-listing-headline" mt="md" mb="xs">
        Auch interessant
    </headline-container>
    <v-row class="mb-16 newsLatestListing-row">
        <v-col class="single-row" v-for="(news, n) in newsArray" :key="n" v-bind="cols">
            <router-link
                :to="'/news/' + news.node.slug"
                v-ripple
                height="100%"
                width="100%"
            >
                <v-row>
                    <v-col cols="12" md="6" class="img-col">
                        <custom-image
                            :image="getNewsImage(news)"
                            class="image"
                            cover
                        />
                    </v-col>
                    <v-col cols="12" md="6" class="text-col">
                        <div class="text-container">
                            <span class="date" v-if="news.node.date">
                                {{ news.node.date }}
                            </span>
                            <h3 v-if="news.node.texts">
                                {{ news.node.texts[0].headline }}
                            </h3>
                            <custom-button class="read-more" color="black">
                                Alles Lesen
                                <img
                                    class="custom-icon"
                                    :src="
                                        api + '/frontend-icons/arrow-right.svg'
                                    "
                                    alt="weiter"
                                />
                            </custom-button>
                        </div>
                    </v-col>
                </v-row>
            </router-link>
        </v-col>
    </v-row>
</template>

<script setup>
import {ref, onMounted} from "vue";
import CustomButton from "@/components/reuseables/customButton.vue";
import CustomImage from "@/components/reuseables/customImage.vue";
import HeadlineContainer from "@/components/reuseables/headlineContainer.vue";
import {apolloProvider} from "@/plugins/apollo";
import newsListing from "@/graphql/querie/listing/newsListing.graphql";
import {getNewsImage} from "@/mixins/global/globalMethods";

const api = process.env.VUE_APP_API_URL,
    cols = {
        cols: 12,
        offsetSm: 1,
        sm: 10,
        offsetMd: 1,
        md: 10,
        offsetLg: 0,
        lg: 6,
    },
    newsArray = ref({});

function loadNewsListing() {
    apolloProvider.defaultClient
        .query({
            fetchPolicy: "no-cache",
            query: newsListing,
            variables: {
                first: 2,
            },
        })
        .then((response) => {
            newsArray.value = response.data.getNewsListing.edges;
        });
}

onMounted(() => {
    loadNewsListing();
});
</script>
