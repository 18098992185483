<template>
    <a v-for="(link, l) in links" :key="l" v-ripple :href="generateLink(link)" :target="link.target || '_blank'"
       :title="generateText(link)">
        <img
            class="custom-icon"
            :src="api + '/frontend-icons/arrow-right.svg'"
            alt="weiter"
        />
        {{ generateText(link) }}
    </a>
</template>

<script setup>
import {defineProps} from "vue";

defineProps({
    links: {
        type: Array,
        required: true,
    },
});

const generateLink = (link) => {
        return link?.link || ('/' + link?.dataObject?.url);
    },
    generateText = (link) => {
        return link?.title || link?.dataObject?.urlText;
    },
    api = process.env.VUE_APP_API_URL;
</script>

<style lang="scss" scoped>
a {
    margin-bottom: 1rem;
    background-color: white;
    text-decoration: none;
    color: black;
    display: inline-flex;
    justify-content: start;
    align-items: center;
    padding: 0.75rem 0;
    width: 100%;

    img {
        margin-right: 0.5rem;
    }
}
</style>
