<template>
    <div
        class="image-container custom-image"
        :style="[{ aspectRatio: aspectRatio }, { height: containerHeight }, { width: containerWidth }]"
    >
        <v-img
            v-if="src || extSrc || data || image || generalImages"
            ref="customImage"
            :contain="contain"
            :cover="cover"
            :max-height="maxHeight"
            :height="height"
            :min-height="minHeight"
            :position="backgroundPosition"
            :src="fullpath"
            :width="width"
            :max-width="maxWidth"
            :style="{
                backgroundColor: backgroundColor,
                minWidth: minWidth,
                minHeight: minHeight,
            }"
            :class="hover ? 'image-hover' : ''"
            :title="title"
            :alt="alt"
            :copyright="copyright"
        >
            <template v-slot:placeholder>
                <div class="d-flex align-center justify-center fill-height">
                    <v-progress-circular
                        color="grey-lighten-4"
                        indeterminate
                    />
                </div>
            </template>
        </v-img>
        <custom-image-background
            v-if="backgroundImage?.data && backgroundImage.data[0]"
            :backgroundImage="backgroundImage.data[0]"
            :inverted="backgroundImage.inverted"
        />
    </div>
</template>

<script setup>
    import { defineProps, ref, onMounted } from 'vue'
    import CustomImageBackground from '@/components/reuseables/customImage/customImageBackground.vue'

    const api = process.env.VUE_APP_API_URL,
        props = defineProps({
            // SOURCES
            data: {
                type: Object,
                required: false,
            },
            image: {
                type: Object,
                required: false,
            },
            src: {
                type: String,
                required: false,
            },
            extSrc: {
                type: String,
                required: false,
            },
            generalImages: {
                type: Array,
                required: false,
            },
            accessibility: {
                type: Object,
                required: false,
            },

            //SIZES
            containerHeight: {
                type: String,
                default: '100%',
            },
            containerWidth: {
                type: String,
                default: '100%',
            },
            minHeight: {
                type: String,
                required: false,
            },
            height: {
                type: String,
                required: false,
                default: '100%',
            },
            maxHeight: {
                type: String,
                required: false,
            },
            minWidth: {
                type: String,
                required: false,
            },
            width: {
                type: String,
                required: false,
                // default: "100%",
            },
            maxWidth: {
                type: String,
                required: false,
                // default: "100%",
            },
            aspectRatio: {
                type: String,
                required: false,
            },

            //BACKGROUND-POSITION
            backgroundPosition: {
                type: String,
                required: false,
                default: 'center center',
            },

            //BACKGROUND-SIZE
            contain: {
                type: Boolean,
                required: false,
            },
            cover: {
                type: Boolean,
                required: false,
            },
            backgroundSize: {
                type: String,
                required: false,
            },
            backgroundColor: {
                type: String,
                required: false,
                default: 'transparent',
            },
            hover: {
                type: Boolean,
                required: false,
                default: true,
            },
            consoleLog: {
                type: Boolean,
                required: false,
                default: false,
            },
            forcedThumbnail: {
                type: String,
                required: false,
            },
            minThumbnail: {
                type: String,
                required: false,
            },

            // BACKGROUND IMAGE
            backgroundImage: {
                type: Object,
                required: false,
            },
            showOriginal: {
                type: Boolean,
                required: false,
                default: false,
            },
        }),
        customImage = ref(null),
        fullpath = ref(),
        title = ref(),
        alt = ref(),
        copyright = ref()

    function replaceLink(source, to) {
        return source.replace('360', to)
    }

    function defineThumbnail() {
        if (customImage?.value?.$el?.offsetWidth) {
            //GET WIDTH OF THE IMAGE
            let w = customImage.value.$el.offsetWidth

            // GET SOURCE FROM POSSIBLE DATA
            let source = ''
            if (props?.image?.image?.fullpath) source = props.image.image.fullpath
            if (props?.image?.fullpath) source = props.image.fullpath
            if (props?.generalImages?.[0]?.image?.fullpath) source = props?.generalImages?.[0]?.image?.fullpath

            // DEFINE THUMBNAIL
            let definedThumbnail = '1920'
            if (!props?.showOriginal) {
                if (w >= 1320) definedThumbnail = '1920'
                else if (w >= 800 && w < 1320) definedThumbnail = '1280'
                else if (w >= 400 && w < 800) definedThumbnail = '720'
                else if (w < 400) definedThumbnail = '360'
            }

            if (props.forcedThumbnail) {
                definedThumbnail = props.forcedThumbnail
            }

            if (parseInt(definedThumbnail) < parseInt(props.minThumbnail)) {
                definedThumbnail = props.minThumbnail
            }

            // RETURN DATA
            fullpath.value = api + replaceLink(source, definedThumbnail)
        } else {
            //  CHECK 1 SEC LATER, SOME ELEMENTS LOAD SLOW
            setTimeout(() => {
                defineThumbnail()
            }, 1000)
        }
    }

    function defineMetadata() {
        const sources = [props?.data, props?.image, props?.generalImages?.[0]]

        sources.forEach((source) => {
            if (source) {
                if (source.alt) alt.value = source.alt
                if (source.copyright) copyright.value = source.copyright
                if (source.title) title.value = source.title
            }
        })

        if (props?.accessibility) {
            if (props.accessibility.alt) alt.value = props.accessibility.alt
            if (props.accessibility.title) title.value = props.accessibility.title
            if (props.accessibility.copyright) title.value = props.accessibility.copyright
        }
    }

    onMounted(() => {
        defineThumbnail()
        defineMetadata()
    })
</script>

<style lang="scss" scoped>
    .image-container {
        height: inherit;
        display: flex;
        flex-direction: column;
        position: relative;

        .v-img {
            flex-shrink: 1;
            overflow: hidden;
            z-index: 2;

            &.image-hover {
                :deep(.v-img__img) {
                    @extend .image-hover;
                }
            }
        }

        .title-container {
            border-bottom: 2px solid black;
            font-size: 14px;
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
        }
    }

    :deep(.v-responsive__content) {
        align-self: end;
        transform: translateY(100%);
    }
</style>
