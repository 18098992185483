<template>
    <v-expansion-panels
        v-model="panels"
        variant="accordion"
        class="clubChampionshipAccordion"
        :flat="true"
        :rounded="0"
        :static="true"
    >
        <v-expansion-panel v-for="(teaser, k) in data" :key="k">
            <v-expansion-panel-title>
                <p v-if="teaser.headline" class="mb-0">{{ teaser.headline }}</p>
                <template v-slot:actions="{ expanded }">
                    <v-icon :icon="expanded ? 'mdi-minus' : 'mdi-plus'"></v-icon>
                </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
                <custom-image v-for="(image, k) in teaser.images" :key="k" :image="image" container-height="auto"/>
                <div v-if="teaser.intro" v-html="teaser.intro"/>
                <div v-if="teaser.text" v-html="teaser.text"/>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script setup>
import {defineProps, ref} from "vue";
import CustomImage from "@/components/reuseables/customImage.vue";

defineProps({
    data: Object,
});

const panels = ref([0]);
</script>
