<template>
  <transition-group appear name="fade-scale">
    <v-container v-if="data.id" class="mt-12 pageHole-vcontainer">
      <v-row>
        <v-col class="image-hole" cols="12" lg="5" md="4" sm="4" xl="6">
          <hole-navigation
              :data="data"
              :desktop="false"
              :mobile="true"
              class="mb-8"
          />

          <mobile-information
              v-if="windowSize.x < 600"
              :female-easy="data.femaleEasy"
              :female-hard="data.femaleHard"
              :handicap="data.handicap"
              :male-easy="data.maleEasy"
              :male-hard="data.maleHard"
              :par="data.par"
          />

          <custom-image
              v-if="data?.imageHole"
              v-ripple
              :image="data?.imageHole?.[0]"
              contain
              container-height="auto"
              max-height="66vh"
              style="color: white"
              width="100%"
              @click="visibleRef = !visibleRef"
          />
          <vue-easy-lightbox
              v-if="data?.imageHole?.[0]?.image?.original"
              :imgs="api + data?.imageHole?.[0]?.image?.original"
              :visible="visibleRef"
              class="lightbox-light"
              move-disabled
              pinch-disabled
              rotate-disabled
              zoom-disabled
              @hide="onHide"
          />

          <custom-button
              class="default-button"
              @click="dialog = true"
          >Überflug</custom-button>
          <v-dialog
              v-model="dialog"
              transition="dialog-bottom-transition"
              width="100%"
          >
            <v-card>
              <v-card-text>
                <iframe
                    v-if="data?.video?.type === 'vimeo'"
                    width="100%"
                    height="400px"
                    frameborder="0"
                    :src="'https://player.vimeo.com/video/' + data?.video?.data?.id + '?autoplay=1&badge=0&portrait=0'"
                />
              </v-card-text>
              <v-card-actions>
                <custom-button
                    text="Close"
                    variant="text"
                    @click="dialog = false"
                >Fenster schließen</custom-button>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-col>
        <v-col
            class="information-container"
            cols="12"
            lg="7"
            md="8"
            sm="8"
            xl="6"
        >
          <hole-navigation
              :data="data"
              :desktop="true"
              :mobile="false"
              class="mb-8"
          />

          <div class="tips">
            <div class="headline-container mb-4">
              Bahn {{ data.number }}
            </div>
            <p v-if="data.tips" class="secondary-font simple-tips" v-html="formattedText"></p>
            <div v-if="data.tipsWYSIWYG" v-html="data.tipsWYSIWYG" class="formatted-tips"></div>

            <div
                v-if="windowSize.x >= 600"
                class="par-and-handicap"
            >
              <div v-if="data.par" class="par">
                <span>{{ data.par }}</span>
                Par
              </div>
              <div v-if="data.handicap" class="handicap">
                <span>{{ data.handicap }}</span>
                Handicap
              </div>
            </div>
          </div>
          <div v-if="windowSize.x >= 600" class="distance">
            <div class="headline-container mb-0">Entfernungen</div>
            <div v-if="data.maleEasy" class="male-easy">
              <v-icon icon="mdi-gender-male"/>
              <span class="gender"> Herren</span>
              -
              <number
                  :duration="2"
                  :to="data.maleEasy"
                  easing="Power1.easeOut"
              />
              Meter
            </div>
            <div v-if="data.maleHard" class="male-hard">
              <v-icon icon="mdi-gender-male"/>

              <span class="gender"> Herren </span>
              -
              <number
                  :duration="2"
                  :to="data.maleHard"
                  easing="Power1.easeOut"
              />
              Meter
            </div>
            <div v-if="data.femaleEasy" class="female-easy">
              <v-icon icon="mdi-gender-female"/>
              <span class="gender"> Damen </span>
              -
              <number
                  :duration="2"
                  :to="data.femaleEasy"
                  easing="Power1.easeOut"
              />
              Meter
            </div>
            <div v-if="data.maleHard" class="female-hard">
              <v-icon icon="mdi-gender-female"/>

              <span class="gender"> Damen </span>
              -

              <number
                  :duration="2"
                  :to="data.femaleHard"
                  easing="Power1.easeOut"
              />
              Meter
            </div>
          </div>
          <div v-if="data?.distances" class="distances-flexible">
            <div class="headline-container mb-0">Entfernungen</div>
            <div v-for="(el, key) in data.distances" :key="key" :style="{background: el.color}">
              <span class="color" :style="{background: el.color, display: 'none'}"></span>
              <span class="text"> {{ el.text }}</span>
              <number
                  :duration="2"
                  :to="el.distance"
                  easing="Power1.easeOut"
              />
              Meter
            </div>
          </div>
          <div
              v-if="data?.partner?.logo"
              :data-id="data.partner.id"
              class="partner"
          >
            <div class="headline-container">
              Dieses Loch wird Ihnen Präsentiert von
            </div>
            <custom-image
                :image="data.partner.logo[0]"
                max-height="8rem"
                width="100%"
            />
            <p v-html="data.partner.holeText"/>
          </div>
          <hole-navigation
              :data="data"
              :desktop="true"
              :mobile="true"
          />
        </v-col>
      </v-row>
    </v-container>

    <div v-if="data.imageGallery" class="pageHole-image-gallery-container">
      <image-swiper :data="data.imageGallery"/>
    </div>
  </transition-group>
</template>

<script setup>
import CustomImage from "@/components/reuseables/customImage.vue";
import VueEasyLightbox from "vue-easy-lightbox";
import HoleNavigation from "@/pages/pageHole/holeNavigation.vue";
import ImageSwiper from "@/components/listings/imageSwiper.vue";
import MobileInformation from "@/pages/pageHole/mobileInformation.vue";
import {ref, defineProps} from "vue";
import CustomButton from '@/components/reuseables/customButton.vue';

const props = defineProps({
  data: Object,
  windowSize: Object,
  visible: Object,
});

const api = process.env.VUE_APP_API_URL;
const visibleRef = ref(props.visible);
const onHide = ref(() => (visibleRef.value = false))
const dialog = ref(false);
const formattedText = ref(props.data.tips.replace(/\n\n/g, '<br>'));
</script>

<style scoped>
.formatted-tips {
    display: none;
}
</style>
