import gql from "graphql-tag";

import generalImages from "./fragments/general/generalImages.graphql";
// import generalVideos from "./fragments/general/generalVideos.graphql";
import generalLinks from "./fragments/general/generalLinks.graphql";
import generalFiles from "./fragments/general/generalFiles.graphql";
import generalTexts from "./fragments/general/generalTexts.graphql";
import generalH2 from "./fragments/general/generalH2.graphql";
import generalH3 from "./fragments/general/generalH3.graphql";
import generalH4 from "./fragments/general/generalH4.graphql";
import generalH5 from "./fragments/general/generalH5.graphql";
import generalIntro from "./fragments/general/generalIntro.graphql";
import generalWYSIWYGText from "./fragments/general/generalWYSIWYGText.graphql";
import generalTextsWYSIWYG from "./fragments/general/generalTextsWYSIWYG.graphql";
import generalSeo from "./fragments/general/generalSeo.graphql";
import generalObjectRelation from "./fragments/general/generalObjectRelation.graphql";
import wysiwygImageRow from "./fragments/general/wysiwygImageRow.graphql";
import iframe from "./fragments/iframe.graphql";
import header from "./fragments/header.graphql";
import teaser from "./fragments/teaser.graphql";
import course from "./fragments/course.graphql";
import greenfee from "./fragments/greenfee.graphql";
import colsConnector from "./fragments/colsConnector.graphql";
import formType from "./fragments/formType.graphql";
import gallery from "./fragments/gallery.graphql";
import googleMaps from "./fragments/googleMaps.graphql";
import listing from "./fragments/listing.graphql";
import partner from "./fragments/partner.graphql";
import asset from "./fragments/asset.graphql";
import team from "./fragments/team.graphql";
import table from "./fragments/table.graphql";
import teamMember from "./fragments/teamMember.graphql";
import page from "./fragments/page.graphql";
import hole from "./fragments/hole.graphql";
import simplePage from "./fragments/pageTemplate/simplePage.graphql";
import trainer from "./fragments/trainer.graphql";
import lesson from "./fragments/lesson.graphql";
import importantMessage from "./fragments/importantMessage.graphql";
import modal from "./fragments/modal.graphql";
import courseInformation from "./fragments/courseInformation.graphql";
import images from "./fragments/images.graphql";
import partnerClub from "./fragments/partnerClub.graphql";
import testimonial from "./fragments/testimonial.graphql";
import contactWrapper from "./fragments/contactWrapper.graphql";
import contentSectionSteps from "./fragments/contentSectionSteps.graphql";
import contentSectionPreviewTiles from "./fragments/contentSectionPreviewTiles.graphql";
import clubChampionship from "./fragments/clubChampionship.graphql";
import boardMember from "./fragments/boardMember.graphql";
import fileComponent from "./fragments/fileComponent.graphql";
import textWithTitles from "./fragments/textWithTitles.graphql";
import titledList from "./fragments/titledList.graphql";
import event from "./fragments/event.graphql";
import video from "./fragments/video.graphql";
import requestAPI from "./fragments/requestAPI.graphql"

export const GET_PAGE = gql`
    ${generalImages}
    ${generalFiles}
    ${generalLinks}
    ${generalTexts}
    ${generalH2}
    ${generalH3}
    ${generalH4}
    ${generalH5}
    ${generalIntro}
    ${generalWYSIWYGText}
    ${generalTextsWYSIWYG}
    ${generalSeo}
    ${generalObjectRelation}
    ${wysiwygImageRow}
    ${colsConnector}
    ${contentSectionSteps}
    ${contentSectionPreviewTiles}
    ${course}
    ${formType}
    ${gallery}
    ${modal}
    ${googleMaps}
    ${greenfee}
    ${header}
    ${iframe}
    ${teaser}
    ${listing}
    ${partner}
    ${asset}
    ${team}
    ${teamMember}
    ${page}
    ${hole}
    ${images}
    ${table}
    ${simplePage}
    ${trainer}
    ${importantMessage}
    ${lesson}
    ${courseInformation}
    ${partnerClub}
    ${testimonial}
    ${contactWrapper}
    ${clubChampionship}
    ${boardMember}
    ${fileComponent}
    ${textWithTitles}
    ${titledList}
    ${event}
    ${video}
    ${requestAPI}

    query ($filter: String) {
        getPageListing(first: 1, filter: $filter, defaultLanguage: "de") {
            edges {
                node {
                    id
                    name
                    url
                    seo {
                        ...generalSeo
                    }
                    component {
                        metadata {
                            name
                            value
                        }
                        element {
                            ...colsConnector
                            ...contentSectionPreviewTiles
                            ...contentSectionSteps
                            ...course
                            ...header
                            ...iframe
                            ...teaser
                            ...gallery
                            ...modal
                            ...googleMaps
                            ...greenfee
                            ...formType
                            ...listing
                            ...table
                            ...simplePage
                            ...courseInformation
                            ...contactWrapper
                            ...testimonial
                            ...images
                            ...fileComponent
                            ...textWithTitles
                            ...importantMessage
                            ...video
                            ...requestAPI
                        }
                    }
                    links {
                        ...generalLinks
                    }
                }
            }
        }
    }
`;
