<script setup>
    import { defineProps, ref, onMounted, computed } from 'vue'

    const props = defineProps({
        data: Object,
    })

    const closedImportantMessages = ref([props?.data.id])

    function getLocalStorageClosedImportantMessages() {
        closedImportantMessages.value = JSON.parse(localStorage.getItem('closedImportantMessages')) || []
    }

    function closeMessage() {
        closedImportantMessages.value.push(props?.data?.id)
        localStorage.setItem('closedImportantMessages', JSON.stringify(closedImportantMessages.value))
    }

    const checkIfClosed = computed(() => {
        return closedImportantMessages.value.includes(props?.data?.id)
    })

    onMounted(() => {
        getLocalStorageClosedImportantMessages()
    })
</script>

<template>
    <transition name="fade">
        <div
            v-if="!checkIfClosed"
            class="important-message"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi bi-info-circle-fill info-icon"
                viewBox="0 0 16 16"
            >
                <path
                    d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2"
                />
            </svg>
            <div
                v-if="data?.wysiwyg"
                class="wysiwyg"
                v-html="data.wysiwyg"
            />
            <v-btn
                v-if="data?.closeable"
                class="close-button"
                elevation="0"
                color="transparent"
                @click="closeMessage"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    class="bi bi-x-lg"
                    viewBox="0 0 16 16"
                >
                    <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"
                    />
                </svg>
            </v-btn>
        </div>
    </transition>
</template>

<style scoped lang="scss">
    .important-message {
        padding: 1.5rem;
        border-radius: 0.25rem;
        position: relative;

        .info-icon {
            margin-bottom: 1rem;
        }

        button.close-button {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
            padding: 1.25rem 1rem;
            height: auto;
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.33s ease;
    }

    .fade-enter, .fade-leave-to  {
        opacity: 0;
    }
</style>
