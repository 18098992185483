<template>
    <transition-group
        name="fade-scale"
        appear
        v-if="data?.slug && !loading?.value"
    >
        <div
            class="pageTrainer"
            :key="data?.slug"
        >
            <div class="row-with-bg">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <back-to-overview-page target="trainer" />
                        </v-col>
                        <v-col
                            class="img-col"
                            cols="12"
                            md="12"
                            lg="6"
                        >
                            <custom-image
                                v-if="data?.image?.fullpath"
                                :image="data?.image"
                                width="100%"
                                max-height="86vh"
                                v-ripple
                            />
                        </v-col>
                        <v-col
                            class="info-col"
                            cols="12"
                            md="12"
                            lg="6"
                        >
                            <div class="title-row">
                                <custom-image
                                    v-if="data?.titleIcon?.[0]?.image?.fullpath"
                                    :image="data?.titleIcon?.[0]?.image"
                                    width="100%"
                                    max-height="6vh"
                                    v-ripple
                                />
                                <p class="title-text">{{ data?.title }}</p>
                            </div>
                            <h1 class="fullName">{{ data?.firstname }} {{ data?.lastname }}</h1>
                            <p
                                class="description"
                                v-html="data?.description"
                            />
                            <div class="vitaAufgaben-row">
                                <custom-image
                                    v-if="data?.vitaAufgabenIcon?.[0].image?.fullpath"
                                    :image="data?.vitaAufgabenIcon?.[0]?.image"
                                    width="100%"
                                    max-height="6vh"
                                    v-ripple
                                />
                                <div class="texts-col">
                                    <p class="title">Ausbildung:</p>
                                    <p
                                        class="text"
                                        v-html="data?.vitaAufgaben"
                                    />
                                </div>
                            </div>
                            <div class="email-row">
                                <custom-image
                                    v-if="data?.emailIcon?.[0].image?.fullpath"
                                    :image="data?.emailIcon?.[0]?.image"
                                    width="100%"
                                    max-height="6vh"
                                    v-ripple
                                />
                                <div class="texts-col">
                                    <p class="title">Kontakt:</p>
                                    <a
                                        class="link d-block"
                                        :href="'mailto:' + data?.email"
                                        v-html="data?.email"
                                    />
                                    <a
                                        class="link"
                                        :href="data?.link"
                                        v-html="data?.link"
                                    />
                                </div>
                            </div>
                            <div class="phone-row">
                                <custom-image
                                    v-if="data?.phoneIcon?.[0].image?.fullpath"
                                    :image="data?.phoneIcon?.[0]?.image"
                                    width="100%"
                                    max-height="6vh"
                                    v-ripple
                                />
                                <div class="texts-col">
                                    <p class="title">Telefonnummer:</p>
                                    <p
                                        class="text"
                                        v-html="data?.phone"
                                    />
                                </div>
                            </div>
                            <div class="prices-row">
                                <p v-if="data?.pricesTitle" class="title">{{ data?.pricesTitle }}:</p>
                                <div
                                    class="text"
                                    v-html="data?.prices"
                                />
                            </div>
                            <div class="booking-row">
                                <a
                                    class="btn"
                                    :href="data?.booking"
                                    target="_blank"
                                >
                                    Jetzt buchen
                                </a>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
            <v-container>
                <v-row class="wysiwygs-rows">
                    <v-col
                        class="v-col"
                        cols="12"
                        md="6"
                        v-for="(contentBlock, key) in data?.contentBlock"
                        :key="key"
                    >
                        <p
                            class="title"
                            v-html="contentBlock?.title"
                        />
                        <p
                            class="content"
                            v-html="contentBlock?.content"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </transition-group>
    <page-not-found v-if="!loading && !data?.slug" />
    <custom-loading v-if="loading" />
</template>

<script setup>
    import customImage from '@/components/reuseables/customImage.vue'
    import { apolloProvider } from '@/plugins/apollo'
    import { useRoute } from 'vue-router'
    import { onMounted, ref, watch } from 'vue'
    import { GET_TRAINER } from '@/graphql/querie/pages/trainerBySlug'
    import PageNotFound from '@/pages/pageNotFound.vue'
    import BackToOverviewPage from '@/pages/components/backToOverviewPage.vue'
    import CustomLoading from '@/components/reuseables/loading.vue'

    const route = useRoute()
    const data = ref({}),
        loading = ref(false)

    function loadTrainer() {
        data.value = {}
        loading.value = true
        apolloProvider.defaultClient
            .query({
                fetchPolicy: 'no-cache',
                query: GET_TRAINER,
                variables: {
                    filter: '{"slug": {"$like" :"' + route.params.slug + '"}}',
                    lang: 'de',
                },
            })
            .then((response) => {
                data.value = response.data.getTrainerListing.edges[0].node
            })
            .catch((error) => {
                console.error('Error loading trainer:', error)
            })
            .finally(() => {
                loading.value = false
            })
    }

    onMounted(loadTrainer)

    watch(
        () => route.path,
        () => {
            loadTrainer()
        },
        { immediate: true, deep: true }
    )
</script>
