<template>
    <component :is="component[data.template]" :contentSectionPreviewTiles="data"></component>
</template>

<script setup>
    import { defineProps } from 'vue'
    import defaultTemplate from './templates/defaultTemplate.vue'


    defineProps({
        data: Object
    })

    const component = {
        defaultTemplate: defaultTemplate
    }
</script>
