<template>
    <component
        v-if="data.id"
        :is="components[data.template || 'pageHoleDefault']"
        :data="data"
        :windowSize="windowSize"
        :visible="visibleRef"
        :imgsRef="imgsRef"
        :indexRef="indexRef"
    />
    <custom-loading v-else-if="loading" />
    <page-not-found v-else />
</template>

<script setup>
    import { apolloProvider } from '@/plugins/apollo'
    import { GET_HOLE } from '@/graphql/querie/pages/hole'
    import { onMounted, ref } from 'vue'
    import { useRoute } from 'vue-router'
    import { useHead } from 'unhead'
    import PageNotFound from '@/pages/pageNotFound.vue'
    import { watch } from 'vue'
    import { getImagesForLightBox } from '@/mixins/global/globalMethods'
    import CustomLoading from '@/components/reuseables/loading.vue'
    import pageHoleDefault from '@/pages/pageHole/templates/pageHoleDefault.vue'
    import pageHoleWithoutHeader from '@/pages/pageHole/templates/pageHoleWithoutHeader.vue'

    const components = {
        pageHoleDefault,
        pageHoleWithoutHeader,
    }

    const route = useRoute(),
        data = ref({}),
        visibleRef = ref(),
        indexRef = ref(),
        imgsRef = ref(),
        loading = ref(true),
        windowSize = ref({ x: 0, y: 0 })

    watch(() => route.params.fullpath, loadHole)

    function onResize() {
        windowSize.value = { x: window.innerWidth, y: window.innerHeight }
    }

    function setPageMetadata() {
        if (data.value.seo) {
            useHead({
                title: 'Bahn | ' + data.value.seo[0].title,
                link: [
                    {
                        name: 'canonical',
                        content: data.value.seo[0].canonical,
                    },
                ],
                meta: [
                    {
                        name: 'description',
                        content: data.value.seo[0].description,
                    },
                    {
                        name: 'keywords',
                        content: data.value.seo[0].keywords,
                    },
                    {
                        robots: 'robots',
                        content: data.value.seo[0].robots || 'index, follow',
                    },
                ],
            })
        }
    }

    function loadHole() {
        data.value = {}
        loading.value = true
        apolloProvider.defaultClient
            .query({
                fetchPolicy: 'no-cache',
                query: GET_HOLE,
                variables: {
                    filter: '{"url": {"$like" :"' + route.params.fullpath + '"}}',
                    lang: 'de',
                },
            })
            .then((response) => {
                loading.value = false

                if (response.data.getHoleListing.edges[0].node) {
                    data.value = response.data.getHoleListing.edges[0].node

                    visibleRef.value = false
                    indexRef.value = 0

                    if (data.value.imageHole) {
                        imgsRef.value = getImagesForLightBox(data.value?.imageHole)
                    }

                    setPageMetadata()
                } else {
                    data.value = {}
                }
            })
    }

    onMounted(() => {
        loadHole()
        onResize()
    })
</script>
