import gql from "graphql-tag";
import generalImages from "../fragments/general/generalImages.graphql";
import event from "../fragments/event.graphql";
export const GET_EVENT = gql`
    ${event}
    ${generalImages}
    query ($filter: String) {
        getEventListing(
            filter: $filter
            defaultLanguage: "de"
            first: 1
        ) {
            edges{
                node{
                    ...event
                }
            }
        }
    }
`;
