<template>
    <v-expansion-panels
        v-model="panels"
        variant="accordion"
        class="clubChampionshipAccordion"
        :flat="true"
        :rounded="0"
        :static="true"
    >
        <v-expansion-panel
            v-for="(championship, k) in championshipCollection"
            :key="k"
        >
            <v-expansion-panel-title>
               <p class="mb-0">{{ championship.title }}</p>
                <template v-slot:actions="{ expanded }">
                    <v-icon :icon="expanded ? 'mdi-minus' : 'mdi-plus'"></v-icon>
                </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
                <v-img
                    :src="api + championship.picture.fullpath"
                ></v-img>
                <p>{{championship.pictureDescription}}</p>
                <v-row
                    no-gutters
                >
                    <v-col
                        v-for="(champion, k) in championship.championsBlock"
                        :key="k"
                        cols="6"
                        class="championBlock pl-2"
                    >
                        <v-row
                            no-gutters
                            class="mb-4"
                        >
                            <v-col cols="12" class="v-col-md-5 pr-2">
                                <p class="mb-0 bold">{{champion.category}}</p>
                            </v-col>
                            <v-col cols="12" class="v-col-md-7">
                                <p class="mb-0">{{champion.name}}</p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script setup>
import {defineProps, ref} from "vue";

const props = defineProps({
    data: Object,
});

const panels = ref([0]);

const championshipCollection = ref(props.data);

const api = process.env.VUE_APP_API_URL;


</script>
<style scoped>
    .championBlock{
        p{
            word-break: keep-all;
        }
    }
</style>
