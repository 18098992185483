import {
    ApolloClient,
    HttpLink,
    InMemoryCache,
    ApolloLink,
} from "@apollo/client/core";
import { createApolloProvider } from "@vue/apollo-option";

const httpLinkApi = new HttpLink({
    uri: process.env.VUE_APP_API_URL + "/pimcore-graphql-webservices/"+process.env.VUE_APP_API_ENDPOINT,
});

const middlewareLink = new ApolloLink((operation, forward) => {
    operation.setContext({
        headers: {
            Bearer: null,
            "Access-Control-Allow-Origin": "*",
            "X-API-Key": process.env.VUE_APP_API_KEY,
        },
    });
    return forward(operation);
});

const openLink = middlewareLink.concat(httpLinkApi);

export const apiClient = new ApolloClient({
    link: openLink,
    cache: new InMemoryCache(),
    defaultOptions: {
        fetchPolicy: "no-cache",
    },
    connectToDevTools: true,
    headers: {
        "Access-Control-Allow-Origin": "*",
    },
});

export const apolloProvider = new createApolloProvider({
    defaultClient: apiClient,
    clients: {
        apiClient,
    },
});
