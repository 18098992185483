<template>
    <page-news-v2
        :data="data"
        v-if="data.id"
    />
    <custom-loading v-else-if="loading" />
    <page-not-found v-else-if="!loading && !data.id" />
</template>

<script setup>
    import { apolloProvider } from '@/plugins/apollo'
    import { useHead } from 'unhead'
    import { useRoute } from 'vue-router'
    import { onMounted, ref, watch } from 'vue'
    import { GET_NEWS } from '@/graphql/querie/pages/news'
    import PageNewsV2 from '@/pages/pageNews/pageNewsV2.vue'
    import CustomLoading from '@/components/reuseables/loading.vue'
    import PageNotFound from '@/pages/pageNotFound.vue'

    const route = ref(useRoute()),
        data = ref({}),
        loading = ref(true)

    function setPageMetadata() {
        if (data.value.seo) {
            useHead({
                title: 'News | ' + data.value.seo[0].title,
                link: [
                    {
                        name: 'canonical',
                        content: data.value.seo[0].canonical,
                    },
                ],
                meta: [
                    {
                        name: 'description',
                        content: data.value.seo[0].description,
                    },
                    {
                        name: 'keywords',
                        content: data.value.seo[0].keywords,
                    },
                    {
                        robots: 'robots',
                        content: data.value.seo[0].robots || 'index, follow',
                    },
                ],
            })
        }
    }

    function loadNews() {
        data.value = {}
        loading.value = true
        apolloProvider.defaultClient
            .query({
                fetchPolicy: 'no-cache',
                query: GET_NEWS,
                variables: {
                    filter: '{"slug": {"$like" :"' + route.value.params.slug + '"}}',
                    lang: 'de',
                },
            })
            .then((response) => {
                loading.value = false

                if (response.data.getNewsListing.edges[0]) {
                    data.value = response.data.getNewsListing.edges[0].node
                } else {
                    data.value = {}
                }

                setPageMetadata()
            })
    }

    onMounted(loadNews)

    watch(
        () => route.value.path,
        () => {
            loadNews()
        },
        { immediate: true, deep: true }
    )
</script>
