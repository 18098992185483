<template>
    <component :is="components[data.name]" :id="data.name + '-' + data.id" />
</template>
<script setup>
    import { defineProps, onMounted } from 'vue'
    import courseinfoWidget from '@/components/fileComponents/courseinfoWidget.vue'

    const components = {
        courseinfoWidget,
    }

    const props = defineProps({
        data: {
            type: Object,
            required: true,
        },
    })

    onMounted(() => {
        const styleId = 'dynamic-style'
        if (!document.getElementById(styleId) && props.data.css) {
            const style = document.createElement('style')
            style.id = styleId
            style.type = 'text/css'
            style.innerHTML = props.data.css
            document.head.appendChild(style)
        }
    })
</script>
