<template>
    <object_header-v2 v-if="template === 'template2'" :data="data" :back="back"/>
    <object_header-v1 v-else :data="data" :back="back"/>
</template>

<script setup>
import {defineProps, computed, onMounted, onUnmounted} from "vue";
import Object_headerV2 from "@/components/object_header/object_headerV2.vue";
import Object_headerV1 from "@/components/object_header/object_headerV1.vue";
import {useThemeStore} from "@/store/theme";

const themeStore = useThemeStore(),
    defaultHeroTemplate = themeStore.defaultHeroTemplate,
    props = defineProps({
        data: Object,
        back: Array,
    }),
    template = computed(() => {
        let temp = null
        if (defaultHeroTemplate) temp = defaultHeroTemplate
        if (props?.data?.template) temp = props?.data?.template
        return temp
    });

function emitHeroMountedStatus(status) {
    const event = new CustomEvent("heroMountedStatus", { detail: { status } });
    window.dispatchEvent(event);
}

onMounted(() => {
    emitHeroMountedStatus("mounted");
    localStorage.setItem("object_header_mounted", "true");
});
onUnmounted(() => {
    emitHeroMountedStatus("unmounted");
    localStorage.setItem("object_header_mounted", "false");
});
</script>