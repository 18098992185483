<template>
    <object_header
        v-if="data.imagesHeader || data.headerHeadline || data.headerSubline"
        :data="{
            headline: data.headerHeadline,
            subHeadline: data.headerSubline,
            images: [data.imagesHeader[0]],
            sizeOfHero: 'small',
            template: 'template2'
        }"
    />
    <v-container class="object-simple-page">
        <back-button :buttons="back"/>

        <v-row>
            <v-col v-if="data.showNavigation" cols="12" class="d-md-none d-block">
                <simple-page-side-navigation :navigationRoot="data.navigationRoot"/>
            </v-col>

            <v-col v-if="data.showNavigation" cols="12" md="4" lg="3" class="side-info" order="2" order-md="1">
                <simple-page-side-navigation class="d-none d-md-block" :navigationRoot="data.navigationRoot"/>
                <simple-page-infos
                    :gallery-headline="data.imagesHeadline"
                    :gallery="data.images"
                    :files-headline="data.filesHeadline"
                    :files="data.files"
                    :links-headline="data.linksHeadline"
                    :links="data.links"
                />
            </v-col>
            <v-col v-bind="getContentColsWidth()" order="1" order-md="2" class="text-col"
                   :class="{'padding-left' : data.showNavigation }">
                <headline-container v-if="data.texts[0].headline" mb="xs">
                    {{ data.texts[0].headline }}
                </headline-container>
                <object_simple-page-text
                    v-if="data.texts"
                    :texts="data.texts"
                    :styling="data.textStyling"
                />
            </v-col>
            <v-col v-if="!data.showNavigation" cols="12" md="4" class="side-info" order="3">
                <simple-page-infos
                    :gallery-headline="data.imagesHeadline"
                    :gallery="data.images"
                    :files-headline="data.filesHeadline"
                    :files="data.files"
                    :links-headline="data.linksHeadline"
                    :links="data.links"
                />
            </v-col>
        </v-row>
        <news-latest-listing v-if="data.newsActivate"/>
    </v-container>
</template>

<script setup>
import {defineProps} from "vue";
import Object_simplePageText from "@/components/object_simplePage/object_simplePageText.vue";
import NewsLatestListing from "@/components/listings/newsLatestListing.vue";
import HeadlineContainer from "@/components/reuseables/headlineContainer.vue";
import BackButton from "@/pages/PageLesson/backButton.vue";
import SimplePageSideNavigation from "@/components/object_simplePage/simplePageSideNavigation.vue";
import SimplePageInfos from "@/components/object_simplePage/simplePageInfos.vue";

const props = defineProps({
    data: Object,
    back: Array,
});

function getContentColsWidth() {
    if (props.data.showNavigation) {
        return {cols: "12", md: "8", lg: "9"}
    }
    return {cols: "12", md: "8"}
}
</script>

<style lang="scss" scoped>
.object-simple-page {
    margin-top: 4rem;

    :deep(.newsLatestListing-headline) {
        margin-bottom: 0;
    }
}

.side-info {
    & > :deep(div:not(:last-child)) {
        margin-bottom: 3rem;
    }
}
</style>
