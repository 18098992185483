<template>
    <template v-if="content">
        <custom-button-v2
            class="image-big-box spacing-bottom-xs"
            v-if="content?.links?.[0] || content?.files?.[0] || content?.url"
            :general-link="content?.links?.[0]"
            :general-file="content?.files?.[0]"
            :to="content?.url"
        >
            <h2>
                <span
                    v-if="content?.element?.headline"
                    class="secondary-font mt-1"
                >
                    {{ content.element.headline }}
                </span>
                <span v-if="content?.element?.subline">
                    {{ content.element.subline }}
                </span>
            </h2>

            <custom-image
                v-if="content?.element?.imagesTeaser"
                :image="content.element?.imagesTeaser[0]"
                cover
                :aspect-ratio="aspectRatio"
            />
            <custom-image
                v-if="content?.element?.imageTeaser"
                :image="content.element?.imageTeaser"
                cover
                :aspect-ratio="aspectRatio"
            />

            <div class="button-container">
                <custom-button>
                    {{ cont.element.linkText }}
                    <img
                        class="custom-icon"
                        :src="api + '/frontend-icons/arrow-right.svg'"
                        alt="weiter"
                    />
                </custom-button>
            </div>
        </custom-button-v2>
    </template>
    <template v-else-if="item">
        <custom-button-v2
            class="image-big-box"
            v-if="data?.links?.[0] || data?.files?.[0] || data?.url"
            :general-link="data?.links?.[0]"
            :general-file="data?.files?.[0]"
            :to="data?.url"
        >
            <h2>
                <span
                    v-if="item?.headline"
                    class="secondary-font mt-1"
                >
                    {{ item.headline }}
                </span>
                <span v-if="item?.subline">{{ item.subline }}</span>
            </h2>

            <custom-image
                v-if="item?.imagesTeaser"
                :image="item.imagesTeaser[0]"
                cover
                :aspect-ratio="aspectRatio"
            />
            <custom-image
                v-if="item?.imageTeaser"
                :image="item.imageTeaser"
                cover
                :aspect-ratio="aspectRatio"
            />

            <div class="button-container">
                <custom-button>
                    {{ item.linkText }}
                    <img
                        class="custom-icon"
                        :src="api + '/frontend-icons/arrow-right.svg'"
                        alt="weiter"
                    />
                </custom-button>
            </div>
        </custom-button-v2>
    </template>
</template>

<script setup>
    import { computed, defineProps, ref } from 'vue'
    import CustomButton from '@/components/reuseables/customButton.vue'
    import CustomImage from '@/components/reuseables/customImage.vue'
    import { vuetify } from '@/plugins/vuetify'
    import CustomButtonV2 from '@/components/reuseables/customButtonV2.vue'

    const props = defineProps({
        data: {
            type: Object,
            required: false,
        },
        content: {
            type: Array,
            required: false,
        },
        items: {
            type: Array,
            required: false,
        },
        item: {
            type: Object,
            required: false,
        },
        listing: {
            type: Object,
            required: false,
        },
        listingData: {
            type: Object,
            required: false,
        },
    })

    const api = process.env.VUE_APP_API_URL

    const aspectRatio = computed(() => {
        const display = ref(vuetify.display)

        let aspectRatio = {
            base: props?.listingData?.baseAR || '16/9',
            sm: props?.listingData?.smallAR || '16/9',
            md: props?.listingData?.mediumAR || '16/9',
            lg: props?.listingData?.largeAR || '16/9',
            xl: props?.listingData?.extraLargeAR || '16/9',
            xxl: props?.listingData?.xxlAR || '16/9',
        }

        for (const size of ['xxl', 'xl', 'lg', 'md', 'sm']) {
            if (display.value[size]) {
                return aspectRatio[size]
            }
        }

        return aspectRatio.base
    })
</script>
