<template>
    <swiper
        class="custom-swiper"
        :breakpoints="breakpoints"
        :modules="modules"
        :navigation="true"
        :pagination="{ enabled: true }"
    >
        <swiper-slide v-for="(item, i) in data.content" :key="i">
            <component
                :is="item.element.__typename"
                :data="item.element"
            />
        </swiper-slide>
    </swiper>
</template>

<script>
import {Swiper, SwiperSlide} from "swiper/vue";
import object_testimonial from "@/components/object_testimonial.vue";
import {Navigation, Pagination} from "swiper/modules";

export default {
    name: "ListConfigSlider",
    components: {Swiper, SwiperSlide, object_testimonial},
    props: ["data", "items", "config"],
    computed: {
        breakpoints() {
            const breakpoints = {0: {}};

            if (this.config.DefaultOptions) {
                this.config.DefaultOptions.forEach(option => {
                    breakpoints['0'][option.Option] = option.Val;
                });
            }

            if (this.config.ResponsiveOptions) {

                this.config.ResponsiveOptions.forEach(responsiveOption => {
                    const minWidth = responsiveOption.minWidth;
                    const options = {};
                    responsiveOption.SliderOptions.forEach(option => {
                        options[option.Option] = option.Val;
                    });
                    breakpoints[minWidth] = options;
                });
            }

            return breakpoints;
        }
    },
    setup() {
        return {
            modules: [Navigation, Pagination],
        }
    }
};
</script>

<style lang="scss" scoped>
.custom-swiper {
    :deep(.swiper-wrapper) {
        padding-bottom: 3rem;
        .swiper-slide {
            padding: 0 6rem;
            box-sizing: border-box;
            @media (max-width: 800px) {
                padding: 0 4rem;
            }
        }
    }
}
</style>