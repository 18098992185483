<template>
    <component
        :is="linkAttributes.is"
        v-ripple
        class="custom-button"
        @click="$emit('click')"
        flat
        v-bind="linkAttributes"
        :style="[{ color: color }, { backgroundColor: bgColor }]"
        rounded="0"
    >
        <slot>{{ linkAttributes.title }}</slot>
    </component>
</template>

<script setup>
    import { ref, defineProps, onMounted } from 'vue'

    const linkAttributes = ref({})
    const api = process.env.VUE_APP_API_URL
    const props = defineProps({
        generalLink: {
            type: Object,
            required: false,
        },
        generalFile: {
            type: Object,
            required: false,
        },
        to: {
            type: String,
            required: false,
        },
        color: {
            type: String,
            required: false,
        },
        bgColor: {
            type: String,
            required: false,
        },
    })

    function processLinkAttributes() {
        if (props.to) {
            linkAttributes.value = {
                is: 'router-link',
                to: props.to,
            }
        } else if (props.generalFile) {
            const { file, text } = props.generalFile
            linkAttributes.value = {
                is: 'a',
                href: api + file.fullpath,
                download: true,
                title: text,
                target: '_blank',
            }
        } else if (props.generalLink) {
            const { link, dataObject, title, classes, target } = props.generalLink
            let finalTitle = title || dataObject?.urlText

            if (link?.includes('https')) {
                linkAttributes.value = {
                    is: 'a',
                    href: link,
                    target: target,
                }
            } else if (dataObject?.externalUrl) {
                linkAttributes.value = {
                    is: 'router-link',
                    to: '/' + dataObject.externalUrl,
                    target: target,
                }
            } else if (dataObject?.url) {
                linkAttributes.value = {
                    is: 'router-link',
                    to: '/' + dataObject.url,
                    target: target,
                }
            } else {
                linkAttributes.value = {
                    is: 'router-link',
                    to: '/' + link,
                    target: target,
                }
            }

            linkAttributes.value.title = finalTitle
            linkAttributes.value.classes = classes
        } else {
            linkAttributes.value = { link: null, title: null, classes: null }
        }
    }

    onMounted(() => {
        processLinkAttributes()
    })
</script>

<style lang="scss" scoped>
    .custom-button {
        letter-spacing: 0.5px;
        text-transform: unset;
        height: auto !important;

        :deep(i) {
            margin-bottom: 0.125rem;
            margin-left: 0.25rem;
        }

        :deep(.v-btn__content) {
            white-space: normal;
            text-align: center;
        }
    }
</style>
