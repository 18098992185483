<template>
    <section class="listing-card-row">
        <article class="single-card">
            <custom-image
                class="card-main-image"
                v-if="data?.bigImage?.[0]"
                :image="data?.bigImage?.[0]"
                v-ripple/>
            <div class="information-col">
                <section class="image-text-row">
                    <div class="location">
                        <svg class="location-icon" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path style="fill: none; stroke: #e9048b; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.5px;" d="M20.39,15.52v3.1c0,1.45-1.18,2.63-2.63,2.63H6.24c-1.45,0-2.63-1.18-2.63-2.63V7.11c0-1.45,1.18-2.63,2.63-2.63h11.51c1.45,0,2.63,1.18,2.63,2.63v3.63l-16.63-.07"/>
                            <g>
                                <line style="fill: none; stroke: #e9048b; stroke-miterlimit: 10; stroke-width: 1.5px;" x1="7.77" y1="2.08" x2="7.77" y2="6.95"/>
                                <line style="fill: none; stroke: #e9048b; stroke-miterlimit: 10; stroke-width: 1.5px;" x1="16.23" y1="2.08" x2="16.23" y2="6.95"/>
                            </g>
                        </svg>
                        <div class="location" v-if="data?.location" v-html="data?.location"/>

                    </div>
                    <div class="duration">
                        <svg class="duration-icon" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path fill="none" stroke="#e9048b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5px" d="M5.9,13.96l5.3,7.6c.4.5,1.1.5,1.6,0l5.3-7.6c2.1-3,1.8-7.3-.9-9.9-.2-.2-.4-.3-.6-.5-2.7-2-6.5-2-9.2,0-.2.3-.4.4-.6.6-2.7,2.5-3,6.7-.9,9.8Z"/>
                            <path fill="none" stroke="#e9048b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5px" d="M11.65,11.57c1.6,0,2.8-1.3,2.8-2.8,0-1.6-1.3-2.8-2.8-2.8s-2.8,1.2-2.8,2.8,1.2,2.8,2.8,2.8Z"/>
                        </svg>
                        <div class="duration" v-if="data?.duration" v-html="data?.duration"/>

                    </div>
                </section>
                <router-link
                    class="title"
                    v-if="data?.slug && !isExternalLink(data?.slug)"
                    :to="'/events/' + data?.slug"
                    :data-id="data?.id"
                    :key="data?.id"
                    v-ripple>
                    {{ data?.title }}
                </router-link>
                <a
                    class="title"
                    v-if="data?.slug && isExternalLink(data?.slug)"
                    :href="data?.slug">
                    Alle Details
                </a>
            </div>

            <div class="btn-container">
                <router-link
                    class="button"
                    v-if="data?.slug && !isExternalLink(data?.slug)"
                    :to="'/events/' + data?.slug"
                    :data-id="data?.id"
                    :key="data?.id"
                    v-ripple>
                    Alle Details
                </router-link>
                <a
                    class="button"
                    v-if="data?.slug && isExternalLink(data?.slug)"
                    :href="data?.slug">
                    Alle Details
                </a>
            </div>
        </article>
    </section>
</template>

<script setup>
import {defineProps} from "vue";
import CustomImage from "@/components/reuseables/customImage.vue";

defineProps({
    data: Object,
});

const isExternalLink = ((slug) => {
    const validLinkPrefixes = [
        'http://',
        'https://',
    ];
    return validLinkPrefixes.some(prefix => slug.startsWith(prefix));
});

</script>
