import { createRouter, createWebHistory } from "vue-router";
import pageCMS from "@/pages/pageCMS.vue";
import pageNewsRoot from "@/pages/pageNews/pageNewsRoot.vue";
import pageHole from "@/pages/pageHole/pageHole.vue";
import pageTeam from "@/pages/pageTeam/pageTeam.vue";
import pageLesson from "@/pages/pageLesson/pageLesson.vue";
import pageTrainer from "@/pages/pageTrainer/pageTrainer.vue";
import pageEvents from "@/pages/pageEvents/pageEvents.vue";

const routes = [
    {
        name: "events",
        path: "/events/:slug",
        component: pageEvents,
    },
    {
        name: "hole",
        path: "/hole/:fullpath",
        component: pageHole,
    },
    {
        name: "lesson",
        path: "/lesson/:slug",
        component: pageLesson,
    },
    {
        name: "news",
        path: "/news/:slug",
        component: pageNewsRoot,
    },
    {
        name: "trainer",
        path: "/trainer/:slug",
        component: pageTrainer,
    },
    {
        name: "team",
        path: "/team/:fullpath",
        component: pageTeam,
    },
    {
        path: "/:url(.*)",
        name: "cms",
        component: pageCMS,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    // Scroll-Position zurücksetzen
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });

    next();

    // for Safari
    setTimeout(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, 100);
});

export default router;
