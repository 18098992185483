<template>
    <v-row class="news-overview">
        <template v-for="(news, k) in listing">
            <v-col
                v-if="k < 2"
                cols="12"
                sm="12"
                offset-md="1"
                md="10"
                offset-lg="0"
                lg="6"
                :key="k"
                class="upper-news hover-background-color"
                v-ripple
            >
                <router-link :to="'/news/' + news.node.slug">
                    <v-row>
                        <v-col cols="12" md="6" class="image-area">
                            <custom-image
                                :image="getNewsImage(news)"
                                class="image"
                                height="10rem"
                                cover
                            />
                        </v-col>
                        <v-col cols="12" md="6" class="text-area">
                            <div class="text-container">
                                <span
                                    v-if="news.node.date"
                                    class="date primary-font">
                                    {{ news.node.date }}
                                </span>
                                <h3
                                    v-if="news.node.texts"
                                    class="secondary-font-bold"
                                    v-html="news.node.texts[0].headline"
                                />
                                <custom-button
                                    class="read-more primary-font"
                                    bg-color="transparent"
                                >
                                    Alles Lesen
                                    <img
                                        class="custom-icon"
                                        :src="
                                            api +
                                            '/frontend-icons/arrow-right.svg'
                                        "
                                        alt="weiter"
                                    />
                                </custom-button>
                            </div>
                        </v-col>
                    </v-row>
                </router-link>
            </v-col>
        </template>

        <v-col cols="12">
            <ul class="news-list">
                <template v-for="(news, i) in listing" :key="i">
                    <li v-if="(i > 1) & (i < 5)">
                        <router-link
                            :to="'/news/' + news.node.slug"
                            v-ripple
                            class="link-container hover-background-color"
                        >
                            <div class="text-container">
                                <span class="date primary-font">
                                    {{ news.node.date }}
                                </span>
                                <span class="dash primary-font">&mdash;</span>
                                <span class="headline secondary-font-bold">
                                    <template v-if="news.node.texts">
                                        {{ news.node.texts[0].headline }}
                                    </template>
                                </span>
                                <span class="dash secondary-font">&mdash;</span>
                                <template v-if="news.node.texts">
                                    <span
                                        class="teaser secondary-font font-weight-medium"
                                        v-html="
                                            news.node.texts[0].intro.replace(
                                                /(<([^>]+)>)/gi,
                                                ''
                                            )
                                        "
                                    >
                                    </span>
                                </template>
                            </div>

                            <custom-button
                                :to="'/news/' + news.node.slug"
                                v-ripple
                                class="primary-font"
                            >
                                Alles Lesen
                                <img
                                    class="custom-icon"
                                    :src="
                                        api + '/frontend-icons/arrow-right.svg'
                                    "
                                    alt="weiter"
                                />
                            </custom-button>
                        </router-link>
                    </li>
                </template>
            </ul>
        </v-col>

        <div v-if="data.newsAllLinks" class="show-more-news">
            <div class="line"></div>
            <custom-button
                bg-color="transparent"
                color="black"
                class="all-news-button"
                :to="'/' + data.newsAllLinks[0]?.dataObject?.url"
            >
                alle News anzeigen
            </custom-button>
        </div>
    </v-row>
</template>

<script setup>
import {defineProps} from "vue";
import CustomImage from "@/components/reuseables/customImage.vue";
import CustomButton from "@/components/reuseables/customButton.vue";
import {getNewsImage} from "@/mixins/global/globalMethods";

const api = process.env.VUE_APP_API_URL;

defineProps({
    data: Object,
    items: Object,
    listing: Object,
});
</script>

<style lang="scss" scoped>
.all-news-button {
    text-transform: unset;
}

.headline-container {
    display: flex;
    justify-content: center;
    margin-top: 5rem;

    h2 {
        padding: 0.375rem 3rem 0.25rem 3rem;
        text-align: center;
        margin-bottom: 2rem;
        display: block;
    }
}

a {
    text-decoration: none;
}

.upper-news {
    .image {
        background-size: cover;
        width: 100%;
        height: 12rem;
        max-height: 50vh;
        background-position: center center;
        @media (max-width: 960px) {
            height: 20rem;
        }
    }

    .text-container {
        height: 100%;
        position: relative;
        text-decoration: none;
        color: black;
        @media (max-width: 960px) {
            border-top: none;
            padding-top: 0;
        }

        * {
            text-decoration: none;
        }

        .date {
            display: block;
            margin-bottom: 0.25rem;
        }

        h3 {
            font-size: 18px;
            line-height: 24px;
        }

        .read-more {
            position: absolute;
            bottom: 0;
            right: 0;
            height: auto;
            padding: 1rem;

            i {
                margin-left: 0.375rem;
                margin-bottom: 0.25rem;
            }

            @media (max-width: 960px) {
                position: relative;
                float: right;
                margin: 1rem 0;
            }
        }
    }
}

.news-list {
    padding: 0;
    width: 100%;
    margin-top: 2rem;

    li {
        list-style: none;

        .link-container {
            width: 100%;
            display: flex;
            gap: 1rem;
            padding: 0.75rem 0;
            font-size: 18px;
            color: black;
            align-items: center;

            @media (max-width: 960px) {
                align-items: end;
            }

            .text-container {
                display: flex;
                width: calc(100% - 200px);
                gap: 1rem;
                @media (max-width: 960px) {
                    flex-direction: column;
                    width: calc(100% - 160px);
                    gap: 0.25rem;
                }

                .date {
                    font-size: 1rem;
                }

                .dash {
                    @media (max-width: 960px) {
                        display: none;
                    }
                }

                span {
                    flex-shrink: 0;
                }

                .headline {
                    font-weight: bold;
                    flex-shrink: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    @media (min-width: 961px) {
                        max-width: 50%;
                    }
                }

                .teaser {
                    flex-shrink: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            a {
                margin-left: auto;
                padding-right: 1rem;
                color: black;
                text-transform: none;

                i {
                    margin-left: 0.375rem;
                    margin-bottom: 0.25rem;
                }
            }
        }
    }
}

.show-more-news {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 4rem;

    .line {
        border-bottom: 2px solid black;
        width: 8rem;
        margin-bottom: 1rem;
    }
}
</style>
