<template>
    <v-container
        v-if="data.id"
        class="pageEvents"
    >
        <v-row>
            <v-col cols="12">
                <back-to-overview-page target="events" />
            </v-col>
            <v-col
                cols="12"
                md="7"
                lg="7"
                class="left-col"
            >
                <div class="event-card">
                    <custom-image
                        hover
                        v-if="data?.bigImage"
                        class="image"
                        :image="data?.bigImage?.[0]"
                        cover
                    />
                    <section class="text-container">
                        <div class="meta-information">
                            <span v-if="data?.location">
                                <svg
                                    class="location-icon"
                                    data-name="Layer 1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        style="
                                            fill: none;
                                            stroke: #e9048b;
                                            stroke-linecap: round;
                                            stroke-linejoin: round;
                                            stroke-width: 1.5px;
                                        "
                                        d="M20.39,15.52v3.1c0,1.45-1.18,2.63-2.63,2.63H6.24c-1.45,0-2.63-1.18-2.63-2.63V7.11c0-1.45,1.18-2.63,2.63-2.63h11.51c1.45,0,2.63,1.18,2.63,2.63v3.63l-16.63-.07"
                                    />
                                    <g>
                                        <line
                                            style="
                                                fill: none;
                                                stroke: #e9048b;
                                                stroke-miterlimit: 10;
                                                stroke-width: 1.5px;
                                            "
                                            x1="7.77"
                                            y1="2.08"
                                            x2="7.77"
                                            y2="6.95"
                                        />
                                        <line
                                            style="
                                                fill: none;
                                                stroke: #e9048b;
                                                stroke-miterlimit: 10;
                                                stroke-width: 1.5px;
                                            "
                                            x1="16.23"
                                            y1="2.08"
                                            x2="16.23"
                                            y2="6.95"
                                        />
                                    </g>
                                </svg>
                                {{ data?.location }}
                            </span>
                            <span v-if="data?.duration">
                                <svg
                                    class="duration-icon"
                                    data-name="Layer 1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="none"
                                        stroke="#e9048b"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="1.5px"
                                        d="M5.9,13.96l5.3,7.6c.4.5,1.1.5,1.6,0l5.3-7.6c2.1-3,1.8-7.3-.9-9.9-.2-.2-.4-.3-.6-.5-2.7-2-6.5-2-9.2,0-.2.3-.4.4-.6.6-2.7,2.5-3,6.7-.9,9.8Z"
                                    />
                                    <path
                                        fill="none"
                                        stroke="#e9048b"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="1.5px"
                                        d="M11.65,11.57c1.6,0,2.8-1.3,2.8-2.8,0-1.6-1.3-2.8-2.8-2.8s-2.8,1.2-2.8,2.8,1.2,2.8,2.8,2.8Z"
                                    />
                                </svg>
                                {{ data?.duration }}
                            </span>
                        </div>
                        <h2
                            v-if="data?.title"
                            class="headline"
                            v-html="data.title"
                        />
                        <div
                            v-if="data?.description"
                            class="text"
                            v-html="data.description"
                        />
                    </section>
                </div>
            </v-col>

            <v-col
                cols="12"
                sm="12"
                md="5"
                lg="5"
                class="right-col"
            >
                <page-news-gallery
                    v-if="data?.id"
                    :data="{ imageGallery: data?.gallery }"
                    showTextUnder
                />
            </v-col>
        </v-row>
    </v-container>
    <custom-loading v-else-if="loading" />
    <page-not-found v-else />
</template>
<script setup>
    import { apolloProvider } from '@/plugins/apollo'
    import { useRoute } from 'vue-router'
    import { ref, onMounted } from 'vue'
    import { GET_EVENT } from '@/graphql/querie/pages/eventBySlug'
    import PageNotFound from '@/pages/pageNotFound.vue'
    import PageNewsGallery from '@/pages/pageNews/pageNewsGallery.vue'
    import CustomImage from '@/components/reuseables/customImage.vue'
    import CustomLoading from '@/components/reuseables/loading.vue'
    import BackToOverviewPage from '@/pages/components/backToOverviewPage.vue'

    const route = useRoute(),
        data = ref({}),
        loading = ref(true)

    function loadEvent() {
        data.value = {}
        loading.value = true
        apolloProvider.defaultClient
            .query({
                fetchPolicy: 'no-cache',
                query: GET_EVENT,
                variables: {
                    filter: '{"slug": {"$like" :"' + route.params.slug + '"}}',
                    lang: 'de',
                },
            })
            .then((response) => {
                data.value = response.data.getEventListing.edges[0].node
            })
            .catch((error) => {
                console.error('Error loading event:', error)
            })
            .finally(() => {
                loading.value = false
            })
    }

    onMounted(loadEvent)
</script>
