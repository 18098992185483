<script setup>
    import { defineProps, ref, onMounted } from 'vue'
    import CustomImage from '@/components/reuseables/customImage.vue'
    import VueEasyLightbox from 'vue-easy-lightbox'
    import { getImagesForLightBox } from '@/mixins/global/globalMethods'

    const props = defineProps({
        squad: {
            type: Object,
            required: true,
        },
    })

    const visibleRef = ref(false),
        indexRef = ref(0),
        imgsRef = ref([])

    function onHide() {
        visibleRef.value = false
    }

    onMounted(() => {
        imgsRef.value = getImagesForLightBox(props.squad)
    })
</script>

<template>
    <v-col cols="12">
        <h2>Mannschaftskader</h2>
        <v-row>
            <v-col
                v-for="(member, key) in squad"
                cols="6"
                md="4"
                lg="3"
                :key="key"
            >
                <custom-image
                    :general-images="member.element.images"
                    :accessibility="{ alt: member.element.firstname + ' ' + member.element.lastname }"
                    container-height="auto"
                    @click="(visibleRef = !visibleRef), (indexRef = key)"
                />
            </v-col>
        </v-row>
    </v-col>
    <vue-easy-lightbox
        v-if="squad"
        :visible="visibleRef"
        :imgs="imgsRef"
        :index="indexRef"
        @hide="onHide"
        rotate-disabled
        zoom-disabled
        pinch-disabled
        move-disabled
    />
</template>
