<template>
    <div class="mobile-navigation-button">
        <v-app-bar-nav-icon @click="drawer = !drawer" />

        <teleport to="body">
            <nav class="mobile-navigation" :class="{ show: drawer }">
                <div class="navigation_container">
                    <v-container>
                        <custom-button @click="drawer = false" class="w-100">
                            <v-icon icon="mdi-close" />
                        </custom-button>
                        <v-list density="compact" nav>
                            <template
                                v-for="(page, key) in header.pages"
                                :key="key"
                            >
                                <v-list-item
                                    v-if="page.element.children.length === 0"
                                    :to="'/' + page.element.url"
                                    class="font-weight-bold"
                                    @click="drawer = false"
                                >
                                    {{ page.element.urlText }}
                                </v-list-item>
                                <v-list-group v-else :value="page.element.url">
                                    <template v-slot:activator="{ props }">
                                        <v-list-item
                                            v-bind="props"
                                            class="font-weight-bold"
                                        >
                                            {{ page.element.urlText }}
                                        </v-list-item>
                                    </template>
                                    <v-list-item
                                        v-for="(child, k) in page.element
                                            .children"
                                        :key="k"
                                        v-bind="getNavigationListItemProps(child)"
                                        @click="drawer = false"
                                    >
                                        <v-list-item-title>
                                            {{ child.urlText }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list-group>
                                <hr />
                            </template>
                        </v-list>
                    </v-container>
                </div>
                <v-snackbar
                    v-model="snackbar"
                    timeout="-1"
                    attach="nav"
                    color="#e1e8d7"
                    class="ma-0"
                    rounded="0"
                >
                    <div class="button-snackbar" @click="drawer = false">
                        <navigation-header-desktop-additional-buttons
                            v-if="header.additionalButtons"
                            :additional-buttons="header.additionalButtons"
                        />
                    </div>
                </v-snackbar>
            </nav>
        </teleport>
    </div>
</template>

<script setup>
import CustomButton from "@/components/reuseables/customButton.vue";
import { defineProps, ref } from "vue";
import NavigationHeaderDesktopAdditionalButtons from "@/components/navigation/navigationHeader/navigationHeaderDesktopAdditionalButtons.vue";
import {getNavigationListItemProps} from "@/mixins/global/globalMethods";

defineProps({
    header: {
        type: Object,
        required: true,
    },
});

let drawer = ref(false);
const snackbar = ref(true);
</script>
