<template>
    <div class="contentSectionPreviewTiles">
        <v-row class="header" v-if="contentSectionPreviewTiles.title">
            <v-col>
                <p v-if="contentSectionPreviewTiles.tag">{{contentSectionPreviewTiles.tag}}</p>
                <h2>{{contentSectionPreviewTiles.title}}</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                v-for="(tile,i) in contentSectionPreviewTiles.tiles" :key="i"
                cols="10"
                class="v-col-sm-6 v-col-md-4 v-col-lg-3 offset-sm-0"
                offset="1"
            >
                <v-hover v-slot="{ isHovering, props }">
                    <v-card
                        rounded="lg"
                        elevation="4"
                        v-bind="props"
                    >
                        <template #image>
                            <Transition>
                                <v-img
                                    v-if="isHovering"
                                    :src="api + tile.backgroundPicture.fullpath"
                                    cover
                                ></v-img>
                            </Transition>
                        </template>
                        <template #prepend>
                            <v-img :src="api + tile.icon.fullpath"></v-img>
                        </template>
                        <template #title>
                            <h5>{{tile.title}}</h5>
                        </template>
                        <template #text>
                            <p>{{tile.description}}</p>
                        </template>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
    </div>
</template>

<script setup>
    import { defineProps } from 'vue'
    defineProps({
        contentSectionPreviewTiles: Object
    })

    const api = process.env.VUE_APP_API_URL;

</script>
