<template>
    <v-container v-if="data.id && years" class="game-report-container">
        <template v-for="(year, y) in years" :key="y">
            <v-row v-if="year.some((obj) => obj.visible === true)">
                <v-col cols="12">
                    <headline-container mt="sm">
                        Spielberichte {{ y }}
                    </headline-container>
                </v-col>

                <template v-for="(saison, key) in year" :key="key">
                    <v-col v-if="saison.visible && saison.matchdays" cols="12" lg="3">
                        <div class="year">
                            <span v-if="saison.league">
                                {{ saison.league }}
                            </span>
                        </div>
                    </v-col>
                    <v-col v-if="saison.visible && saison.matchdays[0]" cols="12" lg="9">
                        <v-expansion-panels variant="accordion">
                            <template v-for="(match, key) in reverse(saison.matchdays)" :key="key">
                                <v-expansion-panel
                                    elevation="0"
                                    :collapse-icon="api + '/frontend-icons/dash.svg'"
                                    :expand-icon="api + '/frontend-icons/plus-lg.svg'"
                                    :disabled="!match.report || !match.report === null"
                                >
                                    <v-expansion-panel-title v-ripple>
                                        <template v-slot:default>
                                            <span class="d-flex date-and-matchday">
                                                <span class="date">
                                                    {{ changeDateToLanguage(match.date, "de-DE") }}
                                                </span>
                                                <span class="dash">
                                                    &mdash;
                                                </span>
                                                <span class="match-day primary-font-bold">
                                                    {{ match.enemyTeam }}
                                                </span>
                                            </span>
                                        </template>
                                        <template v-slot:actions="{ expanded }">
                                            <v-icon :icon="expanded ? 'mdi-minus' : 'mdi-plus'"></v-icon>
                                        </template>
                                    </v-expansion-panel-title>
                                    <v-expansion-panel-text>
                                        <div class="secondary-font" v-html="match.report"/>
                                        <game-report-gallery v-if="match.gallery && match.gallery.length > 0"
                                                             :gallery="match.gallery"/>
                                    </v-expansion-panel-text>
                                </v-expansion-panel>
                            </template>
                        </v-expansion-panels>
                    </v-col>
                </template>
            </v-row>
        </template>
    </v-container>
</template>

<script setup>
import {defineProps, onMounted, ref} from "vue";
import {changeDateToLanguage} from "@/mixins/global/globalMethods";
import HeadlineContainer from "@/components/reuseables/headlineContainer.vue";
import GameReportGallery from "@/pages/pageTeam/gameReportGallery.vue";

const props = defineProps({
        data: {
            type: Object,
            required: true,
        },
    }),
    years = ref({}),
    api = process.env.VUE_APP_API_URL;

function getYears() {
    years.value = {};

    props.data.saisons.forEach((saison) => {
        let year = saison.year.split("-")[0];
        if (!years.value[year]) years.value[year] = [];
        years.value[year].push(saison);
    });
}

onMounted(() => {
    getYears();
});

function reverse(matchdays) {
    let array = [];
    matchdays.forEach((e) => array.unshift(e));
    return array;
}
</script>