<template>
    <object_header
        v-if="data?.imageHeader && data?.imageHeader[0]"
        :data="{
            headline: 'Bahn',
            subHeadline: data.number.toString(),
            images: [data.imageHeader[0]],
            sizeOfHero: 'small',
            subAlign: 'text-center',
        }"
        key="header"
    />
    <transition-group
        name="fade-scale"
        appear
    >
        <v-container
            v-if="data.id"
            class="mt-12 pageHole-vcontainer"
        >
            <v-row>
                <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    lg="5"
                    xl="6"
                    class="image-hole"
                >
                    <hole-navigation
                        :desktop="false"
                        :mobile="true"
                        :data="data"
                        class="mb-8"
                    />

                    <mobile-information
                        v-if="windowSize.x < 600"
                        :par="data.par"
                        :handicap="data.handicap"
                        :male-easy="data.maleEasy"
                        :male-hard="data.maleHard"
                        :female-easy="data.femaleEasy"
                        :female-hard="data.femaleHard"
                    />

                    <custom-image
                        v-if="data.imageHole"
                        :image="data.imageHole[0]"
                        max-height="66vh"
                        width="100%"
                        container-height="auto"
                        contain
                        v-ripple
                        @click="visibleRef = !visibleRef"
                        style="color: white"
                    />
                    <teleport to="body">
                        <vue-easy-lightbox
                            v-if="data.imageHole && imgsRef"
                            :visible="visibleRef"
                            :imgs="imgsRef"
                            :index="indexRef"
                            @hide="visibleRef = !visibleRef"
                            class="lightbox-light"
                            rotate-disabled
                            zoom-disabled
                            pinch-disabled
                            move-disabled
                        />
r                    </teleport>
                </v-col>
                <v-col
                    cols="12"
                    sm="8"
                    md="8"
                    lg="7"
                    xl="6"
                    class="information-container"
                >
                    <hole-navigation
                        :desktop="true"
                        :mobile="false"
                        :data="data"
                        class="mb-8"
                    />

                    <div class="tips">
                        <div class="headline-container mb-8">Bahn {{ data.number }}</div>
                        <p
                            v-if="data.tips"
                            class="secondary-font"
                        >
                            {{ data.tips }}
                        </p>

                        <div
                            v-if="windowSize.x >= 600"
                            class="par-and-handicap"
                        >
                            <div
                                v-if="data.par"
                                class="par"
                            >
                                <span>{{ data.par }}</span>
                                par
                            </div>
                            <div
                                class="handicap"
                                v-if="data.handicap"
                            >
                                <span>{{ data.handicap }}</span>
                                handicap
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="windowSize.x >= 600"
                        class="distance"
                    >
                        <div class="headline-container mb-0">Entfernungen</div>
                        <div
                            v-if="data.maleEasy"
                            class="male-easy"
                        >
                            <v-icon icon="mdi-gender-male" />
                            <span class="gender">Herren</span>
                            -
                            <number
                                :to="data.maleEasy"
                                :duration="2"
                                easing="Power1.easeOut"
                            />
                            Meter
                        </div>
                        <div
                            v-if="data.maleHard"
                            class="male-hard"
                        >
                            <v-icon icon="mdi-gender-male" />

                            <span class="gender">Herren</span>
                            -
                            <number
                                :to="data.maleHard"
                                :duration="2"
                                easing="Power1.easeOut"
                            />
                            Meter
                        </div>
                        <div
                            v-if="data.femaleEasy"
                            class="female-easy"
                        >
                            <v-icon icon="mdi-gender-female" />
                            <span class="gender">Damen</span>
                            -
                            <number
                                :to="data.femaleEasy"
                                :duration="2"
                                easing="Power1.easeOut"
                            />
                            Meter
                        </div>
                        <div
                            v-if="data.maleHard"
                            class="female-hard"
                        >
                            <v-icon icon="mdi-gender-female" />

                            <span class="gender">Damen</span>
                            -

                            <number
                                :to="data.femaleHard"
                                :duration="2"
                                easing="Power1.easeOut"
                            />
                            Meter
                        </div>
                    </div>
                    <div
                        v-if="data?.distances"
                        class="distances-flexible"
                    >
                        <div class="headline-container mb-0">Entfernungen</div>
                        <div
                            v-for="(el, key) in data.distances"
                            :key="key"
                            :style="{ background: el.color }"
                        >
                            <span class="text">{{ el.text }}</span>
                            <number
                                :to="el.distance"
                                :duration="2"
                                easing="Power1.easeOut"
                            />
                            Meter
                        </div>
                    </div>
                    <div
                        class="partner"
                        v-if="data?.partner?.logo"
                        :data-id="data.partner.id"
                    >
                        <div class="headline-container">Dieses Loch wird Ihnen Präsentiert von</div>
                        <custom-image
                            :image="data.partner.logo[0]"
                            width="100%"
                            max-height="8rem"
                        />
                        <p v-html="data.partner.holeText" />
                    </div>
                    <hole-navigation
                        :desktop="true"
                        :mobile="true"
                        :data="data"
                    />
                </v-col>
            </v-row>
        </v-container>

        <div
            class="pageHole-image-gallery-container"
            v-if="data.imageGallery"
        >
            <image-swiper :data="data.imageGallery" />
        </div>
    </transition-group>
</template>

<script setup>
    import CustomImage from '@/components/reuseables/customImage.vue'
    import Object_header from '@/components/object_header/object_header.vue'
    import VueEasyLightbox from 'vue-easy-lightbox'
    import HoleNavigation from '@/pages/pageHole/holeNavigation.vue'
    import ImageSwiper from '@/components/listings/imageSwiper.vue'
    import MobileInformation from '@/pages/pageHole/mobileInformation.vue'
    import { ref, defineProps } from 'vue'

    const props = defineProps({
        data: Object,
        windowSize: Object,
        imgsRef: Array,
        indexRef: Number,
        visible: Boolean,
    })

    const visibleRef = ref(props.visible)
</script>
