<template>
    NEWS
    <v-container v-if="listing.length > 0" class="mb-16 newsAll-container">
        <div v-if="windowSize.x > 959" class="news-filter headline-container">
            <h2 class="h3">Themenbereich</h2>
            <div class="button-area">
                <v-item-group v-model="selection" multiple>
                    <v-item
                        v-for="tag in tags"
                        v-slot="{ isSelected, toggle }"
                        :value="tag"
                        :key="tag"
                    >
                        <custom-button
                            v-if="tag !== 'Alle'"
                            :class="isSelected ? 'active' : ''"
                            @click="
                                () => {
                                    toggle();
                                    applyFilter();
                                }
                            "
                            elevation="0"
                        >
                            {{ tag }}
                        </custom-button>
                        <custom-button
                            v-else
                            :class="selection.length === 0 ? 'active' : ''"
                            @click="alleButton"
                            elevation="0"
                        >
                        >
                            {{ tag }}
                        </custom-button>
                    </v-item>
                </v-item-group>
            </div>
        </div>
        <v-row class="news-container">
            <template v-if="filteredList[0].length > 0">
                <v-col
                    cols="12"
                    md="6"
                    lg="4"
                    v-for="(list, key) in filteredList"
                    :key="key"
                >
                    <template v-for="news in list" :key="news.id">
                        <router-link
                            :to="'/news/' + news.slug"
                            class="news-box"
                            v-ripple
                            :data-tags="news.categories?.map((category) => category.name) ?? []"
                        >
                            <custom-image
                                v-if="news.imageTeaser"
                                class="image"
                                :image="news.imageTeaser[0]"
                                cover
                            />
                            <div class="text-container">
                                <div class="info-container">
                                    <span v-if="news.date">{{ news.date }}</span>
                                </div>

                                <h2
                                    class="secondary-font-bold mb-3"
                                    v-if="news.texts"
                                >
                                    {{ news.texts[0].headline }}
                                </h2>
                                <div
                                    class="intro-text"
                                    v-if="news.texts[0].intro"
                                    v-html="news.texts[0].intro"
                                />
                                <div class="news-tags">
                                    <div
                                        class="news-tag"
                                        v-for="(tag, j) in news.categories"
                                        :key="j"
                                    >
                                        <span v-if="j > 0">|</span>{{ tag.name }}
                                    </div>
                                </div>
                                <custom-button width="100%">
                                    alles lesen
                                    <img
                                        class="custom-icon"
                                        :src="
                                        api + '/frontend-icons/arrow-right.svg'
                                    "
                                        alt="weiter"
                                    />
                                </custom-button>
                            </div>
                        </router-link>
                    </template>
                </v-col>
            </template>
        </v-row>
    </v-container>
    <custom-loading v-else/>
</template>

<script setup>
import {
    defineProps,
    ref,
    watch,
    computed,
    onMounted,
    onBeforeUnmount,
} from "vue";
import CustomImage from "@/components/reuseables/customImage.vue";
import CustomButton from "@/components/reuseables/customButton.vue";
import CustomLoading from "@/components/reuseables/loading.vue";
import { getUniqueNewsCategories } from "@/mixins/global/globalMethods";

const props = defineProps({
        listing: {
            type: Object,
        },
    }),
    tags = ref(getUniqueNewsCategories(props.listing)),
    selection = ref([]),
    filteredListing = ref([]),
    api = process.env.VUE_APP_API_URL,
    columnCount = ref(3);

watch(() => props.listing, applyFilter);

onMounted(() => window.addEventListener("resize", updateColumnWidth));
onBeforeUnmount(() => window.removeEventListener("resize", updateColumnWidth));

const windowSize = computed(function () {
    return {x: window.innerWidth, y: window.innerHeight};
});

const filteredList = computed(() => {
    if (!Array.isArray(props.listing)) return;
    const newsColumns = Array.from({length: columnCount.value}, () => []);
    props.listing
        .filter(({node: news}) => {
            if (!news.categories || !news.slug) return false;
            if (selection.value.length === 0) return true;
            return news.categories?.some((category) =>
                selection.value.includes(category.name)
            );
        })
        .forEach(({node: news}, index) =>
            newsColumns[index % columnCount.value].push(news)
        );
    return newsColumns;
});

function applyFilter() {
    if (!Array.isArray(props.listing)) return;
    filteredListing.value = props.listing.filter(({node: news}) => {
        if (selection.value.length === 0) return true;
        return news.categories?.some((category) => selection.value.includes(category.name));
    });
}

function updateColumnWidth() {
    columnCount.value =
        window.innerWidth >= 1280 ? 3 : window.innerWidth >= 960 ? 2 : 1;
}

function alleButton() {
    selection.value = [];
    applyFilter();
}
</script>

<style lang="scss" scoped>
.headline-container {
    padding: 2rem 0 !important;

    h2 {
        margin-bottom: 2rem;
    }
}

.filter-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
}

.news-container {
    .news-box {
        margin-bottom: 2rem;
        color: black;
        text-decoration: none;
        display: block;

        h2 {
            font-size: 24px;
            text-transform: none;
        }

        & > span:nth-child(2) {
            display: block;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }

        .custom-button {
            display: flex;
            padding: 0.5rem;
            justify-content: right;
        }

        .intro-text {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        .news-tags {
            display: flex;
            flex-direction: row;
            margin-top: 1rem;
            margin-bottom: 1rem;
            column-gap: 0.5rem;
            flex-wrap: wrap;

            .news-tag {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 0.5rem;

                span {
                    font-size: 1.5rem;
                }
            }
        }

        .image {
            background-size: cover;
            background-position: center center;
            height: 20rem;
            width: 100%;
        }
    }
}

.headline-container {
    margin-bottom: 3rem;

    h2 {
        margin-bottom: 1rem;
    }

    .tags-container {
        display: flex;
        flex-direction: row;
        gap: 1rem;
    }
}
</style>
