<template>
    <page-not-found v-if="!loading && !data.id"/>

    <customLoading v-else-if="loading"/>

    <template v-else-if="!loading">
        <transition-group appear name="fade-scale">
            <div
                v-for="(comp, k) in data.component"
                :key="k"
                :data-transition-delay-big="k"
                :style="{ backgroundColor: getMetaData(comp.metadata, 'backgroundColor') }"
            >
                <div :class="[
                    getMetaData(comp.metadata, 'container') === '1'
                        ? 'v-container'
                        : '',
                    'spacing-bottom-' +
                        getMetaData(comp.metadata, 'spacingBottom'),
                    'spacing-top-' + getMetaData(comp.metadata, 'spacingTop'),
                    comp.element.template,
                ]">
                    <template
                        v-if="comp.element.__typename === 'object_simplePage' ||
                        comp.element.__typename === 'object_header'">
                        <component
                            :is="comp.element.__typename"
                            :back="data.links"
                            :data="comp.element"
                        />
                    </template>
                    <template v-else>
                        <component
                            :is="comp.element.__typename"
                            :data="comp.element"
                        />
                    </template>
                </div>
            </div>
        </transition-group>
    </template>
</template>

<script>
import object_header from "@/components/object_header/object_header.vue";
import object_teaser from "@/components/object_Teaser.vue";
import object_objectListing from "@/components/object_objectListing.vue";
import object_course from "@/components/object_course.vue";
import { GET_PAGE } from "@/graphql/querie/page";
import object_greenfee from "@/components/object_greenfee.vue";
import object_colsConnector from "@/components/object_colsConnector.vue";
import object_googleMaps from "@/components/object_googleMaps.vue";
import object_contactForm from "@/components/object_contactForm.vue";
import object_gallery from "@/components/object_gallery.vue";
import object_table from "@/components/object_table.vue";
import object_modal from "@/components/object_modal.vue";
import object_images from "@/components/object_images.vue";
import object_iframe from "@/components/object_iframe/object_iframe.vue";
import object_simplePage from "@/components/object_simplePage/object_simplePage.vue";
import object_courseInformation from "@/components/object_courseInformation/object_courseInformation.vue";
import object_contactWrapper from "@/components/object_contactWrapper.vue";
import object_testimonial from "@/components/object_testimonial.vue";
import object_contentSectionSteps from "@/components/object_contentSectionSteps/object_contentSectionSteps.vue";
import object_requestAPI from "@/components/object_requestAPI/object_requestAPI.vue";
import formRenderer from "@/components/form/FormRenderer.vue";
import pageNotFound from "@/pages/pageNotFound.vue";
import { useHead } from "unhead";
import customLoading from "@/components/reuseables/loading.vue";
import object_contentSectionPreviewTiles from "@/components/object_contentSectionPreviewTiles/object_contentSectionPreviewTiles.vue";
import object_fileComponent from '@/components/object_fileComponent.vue';
import object_textWithTitles from '@/components/object_textWithTitles.vue';
import object_importantMessage from '@/components/object_importantMessage.vue';
import object_video from '@/components/object_video.vue';

export default {
    name: "pageCMS",
    components: {
        object_fileComponent,
        object_header,
        object_objectListing,
        object_teaser,
        object_course,
        object_greenfee,
        object_colsConnector,
        object_contactForm,
        object_googleMaps,
        object_gallery,
        object_modal,
        object_iframe,
        object_table,
        object_images,
        object_simplePage,
        object_courseInformation,
        pageNotFound,
        object_formType: formRenderer,
        customLoading,
        object_contactWrapper,
        object_testimonial,
        object_contentSectionSteps,
        object_contentSectionPreviewTiles,
        object_textWithTitles,
        object_importantMessage,
        object_video,
        object_requestAPI
    },
    data() {
        return {
            data: {},
            loading: true,
        };
    },
    created() {
        this.loadPage();
    },
    methods: {
        loadPage() {
            let route = this.$route.params.url;
            if (this.$route.params.url === "") {
                route = "startseite";
            }
            this.loading = true;
            this.data = {};

            this.$apollo
                .query({
                    query: GET_PAGE,
                    variables: {
                        filter: '{"Url": {"$like" :"' + route + '"}}',
                    },
                })
                .then((response) => {
                    this.loading = false;

                    if (response?.data?.getPageListing?.edges[0]) {
                        this.data = response.data.getPageListing.edges[0].node;
                        this.setPageMetadata();
                    } else {
                        this.data = {};
                    }
                });
        },
        setPageMetadata() {
            if (this.data.seo) {
                useHead({
                    title: this.data.seo[0].title,
                    link: [
                        {
                            name: "canonical",
                            content: this.data.seo[0].canonical,
                        },
                    ],
                    meta: [
                        {
                            name: "description",
                            content: this.data.seo[0].description,
                        },
                        {
                            name: "keywords",
                            content: this.data.seo[0].keywords,
                        },
                        {
                            robots: "robots",
                            content: this.data.seo[0].robots || "index, follow",
                        },
                    ],
                });
            }
        },
        getMetaData(metadata, wanted) {
            let value = "";
            metadata.forEach((e) => {
                if (e.name === wanted) {
                    if (e.value) {
                        value = e.value.replace(" ", "");
                    }
                }
            });
            return value;
        },
    },
    watch: {
        $route() {
            this.loadPage();
        },
    },
};
</script>
