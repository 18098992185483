import gql from "graphql-tag";

import generalImages from "../fragments/general/generalImages.graphql";
import generalSeo from "../fragments/general/generalSeo.graphql";

export const GET_HOLE = gql`
    ${generalImages}
    ${generalSeo}

    query ($filter: String) {
        getHoleListing(first: 1, filter: $filter, defaultLanguage: "de") {
            edges {
                node {
                    id
                    name
                    url
                    number
                    imageHole {
                        ...generalImages
                    }
                    imageHeader {
                        ...generalImages
                    }
                    imageGallery {
                        ...generalImages
                    }
                    tips
                    tipsWYSIWYG
                    par
                    handicap
                    maleEasy
                    maleHard
                    femaleEasy
                    femaleHard
                    distances{
                        ... on fieldcollection_holeDistance{
                            text
                            distance
                            color
                        }
                    }
                    partner {
                        ... on object_partner {
                            id
                            name
                            holeText
                            logo {
                                ...generalImages
                            }
                        }
                    }
                    holeBefore {
                        ... on object_hole {
                            number
                            url
                        }
                    }
                    holeAfter {
                        ... on object_hole {
                            number
                            url
                        }
                    }
                    courseOverview {
                        ... on object_page {
                            url
                        }
                    }
                    holeOverview {
                        ... on object_page {
                            url
                        }
                    }
                    seo {
                        ...generalSeo
                    }
                    video {
                        ... on Video {
                            type
                            title
                            description
                            data {
                                ... on VideoDataDescriptor {
                                    id
                                }
                            }
                        }
                    }
                    template
                }
            }
        }
    }
`;
