<template>
    <v-col cols="12">
        <div
            v-for="(img, k) in data.images"
            :key="data.headline + '-' + k"
        >
            <headline-container
                v-if="data.stylingHeadline === 'template-1' && data.headline"
                mb="xs"
            >
                {{ data.headline }}
            </headline-container>
            <h2 v-else-if="data.headline">
                {{ data.headline }}
            </h2>

            <custom-image
                :image="img"
                :hover="false"
                @click="(visibleRef = !visibleRef), (indexRef = k)"
            />

            <template
                v-for="(link, key) in data?.links"
                :key="key"
            >
                <custom-button-v2 :general-link="link" />
            </template>

            <template
                v-for="(file, key) in data?.files"
                :key="key"
            >
                <custom-button-v2 :general-file="file" />
            </template>

            <vue-easy-lightbox
                :visible="visibleRef"
                :imgs="imgsRef"
                :index="indexRef"
                @hide="onHide"
                rotate-disabled
                zoom-disabled
                pinch-disabled
                move-disabled
            />
        </div>
    </v-col>
</template>

<script setup>
    import { defineProps } from 'vue'
    import CustomImage from '@/components/reuseables/customImage.vue'
    import VueEasyLightbox, { useEasyLightbox } from 'vue-easy-lightbox'
    import { getImagesForLightBox } from '@/mixins/global/globalMethods'
    import HeadlineContainer from '@/components/reuseables/headlineContainer.vue'
    import CustomButtonV2 from '@/components/reuseables/customButtonV2.vue'

    const props = defineProps({
            data: Object,
        }),
        {
            // methods
            onHide,
            // refs
            visibleRef,
            indexRef,
            imgsRef,
        } = useEasyLightbox({
            imgs: getImagesForLightBox(props?.data?.images),
            // initial index
            initIndex: 0,
        })
</script>

<style scoped></style>
