<template>
    <iframe
        :id="data.id"
        :data-id="data.id"
        :src="data.iframeLink"
        :title="data.title"
        :alt="data.alt"
        :style="data.aspectRatio ? `height: auto; aspect-ratio: ${data.aspectRatio};` : ''"
        width="100%"
        height="420px"
        frameborder="0"
        allowfullscreen
    />
</template>

<script setup>
import iframeResize from "iframe-resizer/js/iframeResizer";

import { defineProps, onMounted } from "vue";

const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
});

onMounted(() => {
    document
        .querySelector("[data-id='" + props.data.id + "']")
        .addEventListener("load", () => {
            iframeResize({ log: true }, "[data-id='" + props.data.id + "']");
        });
});
</script>
